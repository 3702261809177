function jobFranchiseDocumentRecipientDto(jobFranchiseDocumentRecipientId, firstName, emailAddress, isPrimaryContact) {
  return {
    jobFranchiseDocumentRecipientId: jobFranchiseDocumentRecipientId,
    firstName: firstName,
    emailAddress: emailAddress,
    isPrimaryContact: isPrimaryContact
  }
}

function generateReceiptDto(jobId, employeeId, jobFranchiseDocumentRecipientDtos) {
  return {
    jobId: jobId,
    employeeId: employeeId,
    jobFranchiseDocumentRecipientDtos: jobFranchiseDocumentRecipientDtos
  }
}

function updateJobFranchiseDocumentPhraseDto(jobFranchiseDocumentPhraseId, modifiedBy, customerRepresentativeId, employeeProfileId, overrideReasonId) {
  return {
    jobFranchiseDocumentPhraseId: jobFranchiseDocumentPhraseId,
    modifiedBy: modifiedBy,
    customerRepresentativeId: customerRepresentativeId,
    employeeProfileId: employeeProfileId,
    overrideReasonId: overrideReasonId
  }
}

function createCustomerRepresentativeDto(id, contactId, jobId, signature, firstName, lastName, createdBy) {
  return {
    id: id,
    contactId: contactId,
    jobId: jobId,
    signature: signature,
    firstName: firstName,
    lastName: lastName,
    createdBy: createdBy
  }
}

function createReceiptDto(jobId, franchiseId, employeeId) {
  return {
    jobId: jobId,
    franchiseId: franchiseId,
    employeeId: employeeId
  }
}

module.exports = {
  createCustomerRepresentativeDto,
  createReceiptDto,
  jobFranchiseDocumentRecipientDto,
  generateReceiptDto,
  updateJobFranchiseDocumentPhraseDto
}
