<template>
  <v-card ref="job-details-card-root" class="flex d-flex flex-column" elevation="4" :loading="getIsLoadingJobDetails">
    <v-card-title ref="job-details-card-title" class="headline">
      <v-row>
        <v-col cols="auto" class="d-flex d-inline-flex flex-column pe-0">
          <div ref="job-details-card-title-job-number">{{ this.getCurrentJob.jobNumber }}</div>
        </v-col>
        <v-col>
          <div v-if="this.getCurrentJob.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id" class="float-start">
            <base-tool-tip
              ref="job-details-card-self-scheduled-tool-tip"
              tooltipColor="blue"
              icon-color="blue"
              dialog-title="Customer Self-Scheduled"
              nudge-dialog="0"
              tooltip-icon="mdi-account-tag-outline"
              :is-bottom-dialog="true"
            ></base-tool-tip>
          </div>
        </v-col>
        <v-col v-if="isPinJobIconVisible" class="text-end">
          <v-icon color="primary" @click.stop="addNewJobTab" class="ms-2">mdi-pin-outline</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="auto" class="flex-fill col-lg-6 col-md-12 d-flex">
          <v-select
            class="cursor_pointer class mb-n2"
            ref="job-details-card-preferred-time-of-day-selector"
            color="primary"
            label="Preferred Time of Day"
            :items="getTimeSlots"
            :value="getCurrentJob.preferredTimeSlotId"
            :key="preferredTimeOfDaySelectorKey"
            :disabled="getIsFieldsDisabled"
            @change="promptIsOverAllocationDialogOrUpdatePreferredTimeOfDay"
            item-text="name"
            item-value="id"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="auto" class="flex-fill col-lg-6 col-md-12 d-flex">
          <v-menu
            class="elevation-0 transparent"
            ref="job-details-card-service-date-menu-selector"
            v-model="isMenuVisible"
            :close-on-content-click="false"
            internal-activator
            transition="scale-transition"
            max-height="800px"
            max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pb-4"
                ref="job-details-card-service-date-text-field"
                :value="getFormattedServiceDateAsDayOfWeekMonthDayYear"
                label="Scheduled Date"
                prepend-inner-icon="mdi-calendar"
                :readonly="true"
                :disabled="getIsFieldsDisabled"
                :key="serviceDateTextFieldKey"
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              elevation="0"
              ref="job-details-card-date-picker"
              color="primaryLight"
              no-title
              full-width
              landscape
              :disabled="getIsFieldsDisabled"
              @update:picker-date="pickerUpdateHandler"
              @input="promptIsOverAllocationDialogOrUpdateScheduledDate"
              :value="datePickerValue"
              :min="getMinimumPickerValue"
              :max="maximumDate"
              :events="slotEvents"
            >
              <v-row class="my-1 mx-1">
                <v-col>
                  <v-card flat outlined class="fill-height">
                    <v-card-title>Color Key</v-card-title>
                    <v-card-text class="mt-n4">
                      <v-list color="transparent" shaped>
                        <v-list-item-group v-for="(item, i) in this.colorKeyItems" :key="i">
                          <v-list-item-title id="job-details-color-key-group-title" class="pt-1" v-text="item.title"></v-list-item-title>
                          <v-list-item class="ms-n2 my-md-n2" inactive disabled>
                            <v-list-item-icon class="me-2">
                              <v-icon :color="item.color">mdi-circle-medium</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content :class="`${item.color}--text pa-1 text-capitalize text-md-body-1`" v-text="item.description" />
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat outlined class="fill-height">
                    <v-card-title class="">Order Key</v-card-title>
                    <v-card-text class="mt-n4">
                      <v-list color="transparent" shaped>
                        <v-list-item-group v-for="(item, i) in this.orderKeyItems" :key="i">
                          <v-list-item-title id="job-details-order-key-group-title" class="pt-1" v-text="item.title"></v-list-item-title>
                          <v-list-item class="ms-n2 my-md-n2" inactive disabled>
                            <v-list-item-icon class="me-2">
                              <v-icon :color="item.color">mdi-circle-medium</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content :class="`${item.color}--text pa-1 text-capitalize text-md-body-1`" v-text="item.description" />
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-autocomplete
        class="cursor_pointer"
        ref="job-details-card-lead-source-selector"
        color="primary"
        label="Lead Source"
        :menu-props="{ maxHeight: '275px' }"
        :items="getLeadSources"
        :value="getCurrentJob.leadSourceId"
        :loading="getIsLoadingJobLeadSource"
        key="id"
        :disabled="getIsFieldsDisabled"
        item-text="name"
        item-value="id"
        @change="$emit('lead-source-updated', $event)"
        clearable
        outlined
        hide-details
      ></v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  dateAsDayOfWeekMonthDayYear,
  formatAsDateOnly,
  getMinimumDateOrFirstOfMonth,
  getLastDayOfMonthByYearAndMonth,
  todayAsDate,
  oneYearFromNowAsDate,
  getYearAndMonthFromDateTime
} from "@/utils/DateTimeFormatters"
import { mapActions, mapGetters } from "vuex"
import { fetchScheduleSlotDto, putJobPreferredTimeSlotDto, putJunkJobDto } from "@/api/dtos/JunkDtos"
import { consoleLog } from "@/utils/Logging"
import BaseToolTip from "@/components/BaseToolTip.vue"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import { JobTab } from "@/models/JobTab"

export default {
  name: "JobDetailsCard",
  components: { BaseToolTip },
  data() {
    return {
      isMenuVisible: false,
      minimumDate: todayAsDate(),
      maximumDate: oneYearFromNowAsDate(),
      preferredTimeOfDaySelectorKey: 0,
      serviceDateTextFieldKey: 0,
      colorKeyItems: [
        {
          color: "error",
          description: "No Slots Are Available."
        },
        {
          color: "warning",
          description: "One Slot is Available."
        },
        {
          color: "success",
          description: "Two or More Slots Are Available."
        }
      ],
      orderKeyItems: [
        {
          color: "secondary",
          description: "First Dot Denotes Remaining First Slots."
        },
        {
          color: "secondary",
          description: "Second Dot Denotes Remaining AM Slots."
        },
        {
          color: "secondary",
          description: "Third Dot Denotes Remaining PM Slots."
        },
        {
          color: "secondary",
          description: "Fourth Dot Denotes All Remaining Slots."
        }
      ]
    }
  },
  methods: {
    ...mapActions(["addJobTab"]),
    ...mapActions("Job", [
      "updateScheduledDate",
      "fetchActiveFranchiseCapacitiesByFranchiseIdAndDate",
      "fetchTimeSlots",
      "fetchScheduleSlotAllocations",
      "updateJunkJobPreferredTimeSlot",
      "fetchActiveJunkTrucksInOperatingUnitByDate",
      "fetchActiveFranchiseTaxes",
      "fetchJunkJobTrucks",
      "fetchJunkJobEmployees",
      "fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId"
    ]),
    async addNewJobTab() {
      let tab = new JobTab(
        this.getCurrentJob.id,
        this.getCurrentJob.jobNumber ?? "N/A",
        this.getCurrentJob.customerId,
        this.getPrimaryContactDetails.lastName,
        this.getCustomerContactsWithAddresses.businessName
      )

      await this.addJobTab(tab)
    },
    setMenuVisibility(isVisible) {
      this.isMenuVisible = isVisible
    },
    slotEvents(date) {
      const recordForDate = this.findScheduleSlotAllocationRecordForDate(date)
      if (recordForDate)
        return this.createSlotColors(recordForDate.firstSlotsRemaining, recordForDate.totalAmSlotsRemaining, recordForDate.totalPmSlotsRemaining, recordForDate.totalSlotsRemaining)
      return false
    },
    createSlotColors(firstSlot, amSlot, pmSlot, totalRemainingSlots) {
      if (totalRemainingSlots <= 0) {
        return [
          this.determineEventColor(totalRemainingSlots),
          this.determineEventColor(totalRemainingSlots),
          this.determineEventColor(totalRemainingSlots),
          this.determineEventColor(totalRemainingSlots)
        ]
      }
      return [this.determineEventColor(firstSlot), this.determineEventColor(amSlot), this.determineEventColor(pmSlot), this.determineEventColor(totalRemainingSlots)]
    },
    determineEventColor(numberOfRemainingSlots) {
      if (numberOfRemainingSlots >= 2) return "success"
      else if (numberOfRemainingSlots === 1) return "warning"
      else if (numberOfRemainingSlots < 1) return "error"
    },
    findScheduleSlotAllocationRecordForDate(date) {
      return this.getScheduleSlotAllocations?.find(scheduleSlotAllocation => {
        return formatAsDateOnly(scheduleSlotAllocation?.serviceDate) === formatAsDateOnly(date)
      })
    },
    async promptIsOverAllocationDialogOrUpdateScheduledDate(date) {
      const isOverSlotAllocation = await this.fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId({
        franchiseId: this.getCurrentJob.franchiseId,
        date: formatAsDateOnly(date),
        preferredTimeSlotId: this.getCurrentJob.preferredTimeSlotId
      })
      isOverSlotAllocation ? this.emitUpdateScheduledDate(date) : await this.datePickerInputHandler(date)
    },
    emitUpdateScheduledDate(date) {
      this.$emit("updateScheduledDate", {
        date: dateAsDayOfWeekMonthDayYear(date),
        timeSlot: this.getCurrentJob.preferredTimeSlotName,
        positiveAction: () => this.datePickerInputHandler(date),
        cancellationAction: () => this.revertServiceDateSelection
      })
    },
    async datePickerInputHandler(date) {
      consoleLog("datePickerInputHandler this.$msal.getCurrentUserId() = ", this.$msal.getCurrentUserId())
      const junkJobDto = putJunkJobDto(
        this.getCurrentJob.id,
        date,
        this.getCurrentJob.operatingUnitId,
        this.getCurrentJob.franchiseId,
        this.getCurrentJob.capacityId,
        this.$msal.getCurrentUserId()
      ) // todo: Replace with current user's username when auth is wired up.

      await this.updateScheduledDate(junkJobDto).then(() => {
        this.setMenuVisibility(false)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(this.getCurrentJob.franchiseId, getYearAndMonthFromDateTime(date))
        this.fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({ franchiseId: this.getCurrentJob.franchiseId, date: date })
        this.fetchActiveJunkTrucksInOperatingUnitByDate({ date: date, operatingUnitId: this.getCurrentJob.operatingUnitId })
        this.fetchActiveFranchiseTaxes({ franchiseId: this.getCurrentJob.franchiseId, date: date })
        // this.fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate({ date: date, operatingUnitId: this.getCurrentJob.operatingUnitId })
        this.fetchJunkJobTrucks(this.getCurrentJob.id)
        this.fetchJunkJobEmployees(this.getCurrentJob.id)
      })
    },
    async promptIsOverAllocationDialogOrUpdatePreferredTimeOfDay(timeSlotId) {
      const isOverSlotAllocation = await this.fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId({
        franchiseId: this.getCurrentJob.franchiseId,
        date: this.getCurrentJobScheduledDateOrToday,
        preferredTimeSlotId: timeSlotId
      })
      isOverSlotAllocation
        ? this.emitUpdatePreferredTimeOfDay(timeSlotId)
        : await this.updatePreferredTimeOfDay(timeSlotId).catch(() => {
            this.updatePreferredTimeOfDayKey()
          })
    },
    emitUpdatePreferredTimeOfDay(timeSlotId) {
      this.$emit("updatePreferredTimeOfDay", {
        date: this.getFormattedServiceDateAsDayOfWeekMonthDayYear,
        timeSlot: this.getPreferredTimeOfDayName(timeSlotId),
        positiveAction: () =>
          this.updatePreferredTimeOfDay(timeSlotId).catch(() => {
            this.updatePreferredTimeOfDayKey()
          }),
        cancellationAction: () => this.updatePreferredTimeOfDayKey()
      })
    },
    async updatePreferredTimeOfDay(timeSlotId) {
      const jobPreferredTimeSlotDto = putJobPreferredTimeSlotDto(this.getCurrentJob.id, timeSlotId, this.$msal.getCurrentUserId())

      await this.updateJunkJobPreferredTimeSlot(jobPreferredTimeSlotDto).then(() => {
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(this.getCurrentJob.franchiseId, getYearAndMonthFromDateTime(this.getCurrentJobScheduledDateOrToday))
      })
    },
    async pickerUpdateHandler(yearAndMonth) {
      return await this.fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(this.getCurrentJob.franchiseId, yearAndMonth)
    },
    async fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(franchiseId, yearAndMonth) {
      const fromDate = getMinimumDateOrFirstOfMonth(yearAndMonth)
      const toDate = getLastDayOfMonthByYearAndMonth(yearAndMonth)
      const dto = fetchScheduleSlotDto(franchiseId, fromDate, toDate)
      return await this.fetchScheduleSlotAllocations(dto)
    },
    getPreferredTimeOfDayName(timeSlotId) {
      return this.getTimeSlots?.slice()?.find(timeSlot => timeSlot.id === timeSlotId)?.name ?? ""
    },
    updatePreferredTimeOfDayKey() {
      this.preferredTimeOfDaySelectorKey === 1 ? (this.preferredTimeOfDaySelectorKey = 0) : (this.preferredTimeOfDaySelectorKey = 1)
    },
    revertServiceDateSelection() {
      this.serviceDateTextFieldKey === 1 ? (this.serviceDateTextFieldKey = 0) : (this.serviceDateTextFieldKey = 1)
    }
  },
  computed: {
    JobCreationOrigin() {
      return JobCreationOrigin
    },
    ...mapGetters("Job", [
      "getSelectedFranchise",
      "getCurrentJobScheduledDateOrToday",
      "getIsLoadingJobDetails",
      "getCurrentJob",
      "getTimeSlots",
      "getScheduleSlotAllocations",
      "getFormattedServiceDateAsDayOfWeekMonthDayYear",
      "getIsJobFieldsDisabled",
      "getIsLoadingAnyJobComponents",
      "getCurrentJobScheduledDateOrToday",
      "getPrimaryContactDetails",
      "getCustomerContactsWithAddresses",
      "getLeadSources",
      "getIsLoadingJobLeadSource"
    ]),
    getMinimumPickerValue() {
      return this.getSelectedFranchise?.businessStartDate > todayAsDate() ? this.getSelectedFranchise.businessStartDate : todayAsDate()
    },
    datePickerValue() {
      return formatAsDateOnly(this.getCurrentJobScheduledDateOrToday)
    },
    getIsFieldsDisabled() {
      return this.getIsLoadingJobDetails || this.getIsJobFieldsDisabled || this.getIsLoadingAnyJobComponents
    },
    isPinJobIconVisible() {
      return (
        Object.keys(this.getCurrentJob ?? {}).length !== 0 &&
        Object.keys(this.getPrimaryContactDetails ?? {}).length !== 0 &&
        Object.keys(this.getCustomerContactsWithAddresses ?? {}).length !== 0
      )
    }
  }
}
</script>

<style>
.v-date-picker-table__events {
  margin-inline-start: -6px;
  margin-bottom: -1px;
}
</style>
