<template>
  <v-container fluid>
    <base-dialog
      ref="app-over-allocation-confirmation-dialog-root"
      :is-dialog-visible="isDialogVisible"
      dialog-image="mdi-alert-outline"
      title="Warning: Over Slot Allocation"
      @close-dialog="closeDialog"
      icon-color="error"
      title-background-color="primary"
    >
      <template v-slot:content>
        <v-row>
          <div class="text-md-body-1" ref="app-over-allocation-confirmation-dialog-phrase-one">Scheduling this job will put you over the allocation amount for this job's <strong>Preferred Time of Day: <em>{{ timeSlotName }}</em></strong> and <strong>Scheduled Date: <em>{{ scheduledDate }}</em></strong>.</div>
        </v-row>
        <v-row class="mt-8">
          <span class="text-md-body-1" ref="app-over-allocation-confirmation-dialog-phrase-two">Do you still wish to continue?</span>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="app-over-allocation-confirmation-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" ref="app-over-allocation-confirmation-dialog-confirm-btn" color="primary" @click="confirm" text rounded ripple>Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
export default {
  name: "AppOverAllocationConfirmationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    timeSlotName: {
      type: String,
      default: ""
    },
    scheduledDate: {
      type: String,
      default: ""
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm")
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  }
}
</script>
