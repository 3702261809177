<template>
  <base-dialog ref="administrator-create-discount-dialog-root" title="Create Discount" :is-dialog-visible="isDialogVisible" dialog-image="mdi-tag-plus">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="administrator-create-discount-dialog-form" v-model="isCreateDiscountFormValid">
        <v-layout row>
          <v-text-field
            ref="administrator-create-discount-dialog-name-input"
            label="Discount Name *"
            v-model.trim="discountName"
            hint="This will be displayed on the Junk Service Statement."
            :persistent-hint="true"
            outlined
            :rules="isRequiredRules"
            maxlength="50"
            counter="50"
            @focus="selectAllContent"
          />
        </v-layout>
        <v-layout row>
          <v-text-field
            ref="administrator-create-discount-dialog-promo-code-input"
            label="Promo Code *"
            v-model.trim="promoCode"
            hint="This should be a unique code that customers will enter to receive the discount."
            outlined
            minlength="5"
            maxlength="25"
            counter="25"
            :persistent-hint="true"
            :rules="promoCodeRules"
            @focus="selectAllContent"
            @input="toUpperCase"
          />
        </v-layout>
        <v-layout row class="justify-center">
          <v-col cols="auto">
            <v-chip-group ref="administrator-create-discount-dialog-chip-group" class="mt-1 fill-height flex-fill" color="primary" v-model="isPercentage" @change="setCursorFocusOnAmountField" mandatory>
              <v-chip ref="administrator-create-discount-dialog-chip" label v-for="item in discountUnitChips" :value="item.value" outlined :key="item.value" :aria-label="item.label">
                <v-icon ref="administrator-create-discount-dialog-chip-icon">{{ item.icon }}</v-icon>
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="auto" class="flex-fill mx-0 px-0">
            <v-text-field
              class="text-h5 flex-fill"
              ref="administrator-create-discount-dialog-amount-input"
              :label="getDiscountTypeLabel"
              v-model="discountAmount"
              :hint="getDiscountTypeHint"
              type="number"
              @focus="selectAllContent"
              :rules="discountAmountValidationRules"
              outlined
            />
          </v-col>
        </v-layout>
        <v-layout row class="mb-6">
          <app-date-picker-field-selector
            ref="administrator-create-discount-dialog-begin-date-input"
            text-field-label="Begin Date *"
            :date-picker-value="beginDate"
            :minimum-date-value="minimumDate"
            :maximum-date-value="endDate"
            @updatedPickerValue="setBeginDateTime($event)"
          />
        </v-layout>
        <v-layout row>
          <app-date-picker-field-selector
            ref="administrator-create-discount-dialog-end-date-input"
            text-field-label="End Date"
            is-clear-enabled
            :date-picker-value="endDate"
            :minimum-date-value="beginDate"
            @updatedPickerValue="setEndDateTime($event)"
          />
        </v-layout>
        <v-layout row>
          <v-text-field
            ref="administrator-create-discount-dialog-button-phrase-text-input"
            class="mt-6 mb-2"
            label="Website Button Phrase *"
            v-model.trim="buttonPhrase"
            hint="This will be displayed on the website button."
            :persistent-hint="true"
            outlined
            maxlength="50"
            counter="50"
            @focus="selectAllContent"
            :rules="isRequiredRules"
          />
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="administrator-create-discount-dialog-cancel-button" color="primaryText" @click="closeDialog" text rounded ripple>
        Cancel
      </v-btn>
      <v-btn
        ref="administrator-create-discount-dialog-create-button"
        :loading="isLoading"
        :disabled="isCreateDisabled"
        @click="emitNationalDiscount"
        class="pa-4"
        color="primary"
        text
        rounded
        ripple
      >
        Create
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { mapActions } from "vuex"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { createNationalDiscountDto } from "@/api/dtos/JunkDtos"

export default {
  name: "AdministratorCreateDiscountDialog",
  components: { BaseDialog, AppDatePickerFieldSelector },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      discountName: "",
      isPercentage: false,
      discountAmount: 0.0,
      discountPercent: 0.0,
      beginDate: todayAsDate(),
      endDate: null,
      minimumDate: todayAsDate(),
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      discountUnitChips: [
        { label: "Dollar", icon: "mdi-currency-usd", value: false },
        { label: "Percentage", icon: "mdi-percent-outline", value: true }
      ],
      promoCode: "",
      promoCodeRules: [
        (value) => value.length >= 5 || "Must be at least 5 characters",
        (value) => value.length <= 25 || "Must be no more than 25 characters",
      ],
      discountAmountValidationRules: [
        value => !!value || "Amount is required.",
        value => value > 0 || "Amount must be positive.",
        value => value <= 100.0 || "Amount cannot exceed $100.00."
        // value => value <= 1_000_000.0 && this.isPercentage === false || "Amount cannot exceed $100.00." // todo: in the future when we lift the $100 restriction, add this rule to also predicate off of where the discount is a percentage. Therefore, allowing us to leverage the same rule list, regardless of the unit selected.
      ],
      hasCustomPhrase: false,
      buttonPhrase: "",
      isCreateDiscountFormValid: false
    }
  },
  computed: {
    isFormValid() {
      return this.discountName?.trim() !== "" &&
          this.promoCode?.trim().length >= 5 &&
          this.promoCode?.trim().length <= 25 &&
          this.discountAmount > 0 &&
          this.beginDate !== null &&
          this.buttonPhrase?.trim() !== ""
    },
    isCreateDisabled() {
      return !this.isFormValid || this.isLoading
    },
    getDiscountTypeLabel() {
      return this.isPercentage ? "Percent *" : "Amount *"
    },
    getDiscountTypeHint() {
      return this.isPercentage ? "Please enter a value greater than 0 but less than 100." : "Please enter a value greater than $0.00 but less than $100.00."
    }
  },
  methods: {
    ...mapActions("Administrator", ["createDiscount"]),
    emitNationalDiscount() {
      let amount = this.formatAmount()
      const createDiscountDto = new createNationalDiscountDto(this.discountName, this.promoCode, amount, !!this.isPercentage, this.beginDate, this.endDate, this.buttonPhrase, this.$msal.getCurrentUserId())
      this.$emit("confirm-national-discount", createDiscountDto)
    },
    toUpperCase() {
      this.promoCode = this.promoCode.toUpperCase();
    },
    formatAmount() {
      return this.isPercentage ? Number(this.discountAmount / 100) : Number(this.discountAmount)
    },
    formatPhraseText() {
      this.buttonPhrase = this.buttonPhrase.toUpperCase()
    },
    closeDialog() {
      this.$emit("close-dialog")
    },
    setBeginDateTime(dateTime) {
      this.beginDate = dateTime
    },
    setEndDateTime(dateTime) {
      this.endDate = dateTime
    },
    selectAllContent(event) {
      event.target.select()
    },
    setCursorFocusOnAmountField() {
      this.$refs["administrator-create-discount-dialog-amount-input"].focus()
    }
  }
}
</script>
