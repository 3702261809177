<template>
  <v-layout>
    <v-card class="flex d-flex flex-column flex-fill fill-height elevation-4" style="min-width: 150px; min-height: 200px">
      <v-card-title class="headline">Customer Communications</v-card-title>
      <v-card-text>
        <v-btn
          ref="job-customer-communications-card-resend-confirmation-email-btn"
          @click="handleResendConfirmationEmail"
          :loading="getIsLoadingResendConfirmationEmail"
          :disabled="isResendConfirmationEmailButtonDisabled"
          >Resend Confirmation Email
          <v-icon class="ms-2">mdi-email-sync-outline</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { resendConfirmationEmailDto } from "@/api/dtos/JunkDtos"
import { successSnackbar } from "@/utils/SnackbarBuilder"

export default {
  name: "JobCustomerCommunicationsCard",
  methods: {
    ...mapActions("Job", ["resendConfirmationEmail"]),
    ...mapActions(["addSnackbar"]),
    async handleResendConfirmationEmail() {
      let dto = resendConfirmationEmailDto(this.$msal.getCurrentUserId())
      await this.resendConfirmationEmail(dto).then(() => {
        this.addSnackbar(successSnackbar("Confirmation email has been resent to primary contact", 5000))
      })
    }
  },
  computed: {
    ...mapGetters("Job", ["getCurrentJob", "getIsLoadingResendConfirmationEmail", "getIsJobStatusScheduled", "getIsJobStatusClaimed"]),
    isResendConfirmationEmailButtonDisabled() {
      return this.getIsLoadingResendConfirmationEmail || (!this.getIsJobStatusScheduled && !this.getIsJobStatusClaimed)
    }
  }
}
</script>

<style scoped></style>
