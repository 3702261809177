<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-layout column>
        <div class="float-left mb-4">
          <span ref="job-discount-selector-discount-calculating" class="text-md-body-1 font-weight-bold" v-if="getIsLoadingDiscount">Calculating...</span>
          <span ref="job-discount-selector-discount-total" v-else class="text-md-body-1 font-weight-bold">
            Total Discount Applied:
            <app-number-formatter ref="job-discount-selector-discount-formatter" :amount="getDiscountAmount" currencySign="accounting" numberFormatStyle="currency"></app-number-formatter>
          </span>
        </div>
        <v-autocomplete
          ref="job-discount-selector-autocomplete"
          :label="getComponentTitle"
          :value="getSelectedJobDiscount"
          :items="getActiveDiscounts"
          item-text="discountName"
          :disabled="isDisabled"
          :key="discountSelectorKey"
          return-object
          outlined
          :append-icon="getRemoveDiscountIcon"
          @change="applyDiscount"
          @click:append="removeDiscount"
        />
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { mapActions, mapGetters } from "vuex"
import { putJobDiscountDto } from "@/api/dtos/JunkDtos"
import { isObjectEmpty } from "@/utils/ObjectHelpers"

export default {
  name: "JobDiscountSelector",
  components: { AppNumberFormatter },
  data() {
    return {
      isRemoveDiscountConfirmationDialogVisible: false,
      discountSelectorKey: 0
    }
  },
  computed: {
    ...mapGetters("Job", ["getActiveDiscounts", "getSelectedJobDiscount", "getIsLoadingDiscount", "getCurrentJob", "getIsJobFieldsDisabled"]),
    getComponentTitle() {
      return this.hasDiscount ? "Selected Discount" : "No Discount Applied"
    },
    isDisabled() {
      return this.getIsLoadingDiscount || this.getActiveDiscounts.length === 0 || this.getIsJobFieldsDisabled
    },
    getDiscountAmount() {
      return this.getCurrentJob?.discountAmount ?? 0
    },
    hasDiscount() {
      return !isObjectEmpty(this.getSelectedJobDiscount)
    },
    getRemoveDiscountIcon() {
      return this.hasDiscount ? "mdi-close" : "mdi-blank"
    }
  },
  methods: {
    ...mapActions("Job", ["applyJobDiscount", "removeJobDiscount"]),
    async applyDiscount(item) {
      let dto = putJobDiscountDto(this.getCurrentJob.id, item.franchiseElectedDiscountId, this.$msal.getCurrentUserId())
      await this.applyJobDiscount(dto).catch(() => {
        this.discountSelectorKey === 0 ? (this.discountSelectorKey = 1) : (this.discountSelectorKey = 0)
      })
    },
    async removeDiscount() {
      if (this.hasDiscount) {
        await this.removeJobDiscount(this.$msal.getCurrentUserId())
      }
    }
  }
}
</script>
