import dayjs from "dayjs"
import {
  errorSnackbar,
  errorSnackbarWithPositiveAction,
  infoSnackbar,
  persistentErrorSnackbarWithPositiveAction
} from "@/utils/SnackbarBuilder"
import junkApi from "@/api/Junk"
import * as Sentry from "@sentry/vue"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { fetchScheduleSlotDto } from "@/api/dtos/JunkDtos"
import { dateAsShortenedDayOfWeekMonthAndYear, formatAsDateOnly } from "@/utils/DateTimeFormatters"
import { JobStatus } from "@/enums/JobStatus"
import { JobNoteType } from "@/enums/JobNoteType"
import documentApi from "@/api/Document"
import { v4 } from "uuid"
import { consoleLog } from "@/utils/Logging"

export const state = {
  // job / job details
  currentJob: {},

  customerContactsWithAddresses: {},

  _leadSources: [],

  jobNotes: [],
  jobAddresses: [],
  jobEmployees: [],
  jobTrucks: [],

  activeFranchiseCapacities: [],
  activeFranchiseTaxes: [],
  activeEmployees: [],
  activeTrucks: [],

  franchisesInOperatingUnit: [],
  franchiseSatelliteOffices: [],

  timeSlots: [],
  scheduleSlotAllocations: [],

  jobStatuses: [],
  jobCompletionStatuses: [],
  abandonmentReasons: [],
  cancellationReasons: [],

  trucksAndEmployeesGlobalStartTime: undefined,
  trucksAndEmployeesGlobalEndTime: undefined,
  trucksAndEmployeesGlobalDownTime: undefined,

  // discounts
  _activeDiscounts: [],
  _isLoadingDiscount: false,

  // receipt
  _jobDocumentReceiptInfo: {},
  _jobDocumentReceiptPhrases: [],

  // overrides
  _jobDocumentPhraseEmployeeProfiles: [],
  _jobDocumentPhraseEmployeeProfile: {},

  // signatures
  _customerRepresentativeProfiles: [],

  // receipt UI
  _phraseCheckboxKey: 1,

  // payments
  _jobReceiptPayments: [],

  // receipt recipients
  _allEligibleReceiptRecipients: [],
  _selectedEmailReceiptRecipients: [],

  // loaders
  isLoadingCustomerContactsWithAddresses: false,
  isLoadingEditContact: false,
  _isLoadingDeleteContact: false,
  _isLoadingCustomerAddresses: false,
  isLoadingJob: false,
  isLoadingFranchiseCapacities: false,
  isLoadingJobAddressesReorder: false,
  isLoadingJobAddresses: false,
  isLoadingJobDetails: false,
  _isLoadingJobLeadSource: false,
  isLoadingFranchiseAndSatelliteOffices: false,
  isLoadingJobModifiers: false,
  isLoadingJobNotes: false,
  isLoadingJobTrucksAndEmployees: false,

  _isLoadingEmployeeTips: false,
  _isLoadingTipConfiguration: false,
  _tipConfiguration: {},

  isLoadingCreateContact: false,
  isLoadingUpdateCustomerAddress: false,
  isLoadingUpdateCustomerDetails: false,
  _isLoadingJobDocuments: false,
  _isLoadingCreateSignatureProfile: false,
  _isLoadingCustomerProfiles: false,
  _isLoadingCustomerProfile: false,
  _isLoadingJobDocumentSignatureData: false,
  isLoadingJobFullScreen: { isLoading: false, loadingText: "" },
  isLoadingDeleteCustomerAddress: false,
  _isLoadingPhrase: false,
  _isLoadingCustomerRepresentativeProfile: false,
  _isLoadingAddPayment: false,
  _isLoadingResendConfirmationEmail: false
}

export const getters = {
  // lead sources
  getLeadSources(state) {
    return state._leadSources.slice()
      .sort((a,b) => a.name < b.name ? -1 : 1)
  },
  // discounts
  getActiveDiscounts(state) {
    return state._activeDiscounts ?? []
  },
  getSelectedJobDiscount(state) {
    return state._activeDiscounts.find(d => d.franchiseElectedDiscountId === state.currentJob.franchiseElectedDiscountId) ?? {}
  },
  getIsLoadingDiscount(state) {
    return state._isLoadingDiscount
  },

  // receipt recipients
  getAllEligibleReceiptRecipients(state) {
    return state._allEligibleReceiptRecipients
  },
  getSelectedEmailReceiptRecipients(state) {
    return state._selectedEmailReceiptRecipients
  },

  // receipts
  getJobDocumentReceiptInfo(state) {
    return state._jobDocumentReceiptInfo ?? {}
  },
  getJobDocumentReceiptPhrases(state) {
    return state._jobDocumentReceiptPhrases?.slice() ?? []
  },

  // overrides
  getJobDocumentPhraseEmployeeProfiles(state) {
    return state._jobDocumentPhraseEmployeeProfiles?.slice() ?? []
  },
  getJobDocumentPhraseEmployeeProfile(state) {
    return state._jobDocumentPhraseEmployeeProfile ?? {}
  },

  // signatures
  getAllCustomerContacts(state) {
    consoleLog("OHYEAH.getAllCustomerContacts CALLED!")
    return state.customerContactsWithAddresses?.contactDTOs?.slice() ?? []
  },
  getCustomerRepresentativeProfiles(state) {
    consoleLog("OHYEAH.getCustomerRepresentativeProfiles CALLED!")
    return state._customerRepresentativeProfiles?.slice() ?? []
  },
  getPhraseCheckboxKey(state) {
    return state._phraseCheckboxKey
  },
  // getCurrentJobReceiptCustomerRepresentatives(state, getters) {
  //   consoleLog("OHYEAH.getCurrentJobReceiptCustomerRepresentatives CALLED!")
    // let customerContactProfiles =
    //   [
    //     ...getters.getAllCustomerContacts?.slice().map(contact => {
    //       return {
    //         contactId: contact.id,
    //         customerRepresentativeId: v4(), // this gets reset after merging these lists
    //         name: `${contact.firstName} ${contact.lastName}`,
    //         signature: null, // this gets set with existing signature data after merging these lists
    //         firstName: contact.firstName,
    //         lastName: contact.lastName,
    //         email: contact.emailPrimary,
    //         isPrimary: contact.isPrimary,
    //         jobId: getters.getCurrentJob.id // this is our problem
    //       }
    //     })
    //   ] ?? []
    //
    // const mergedResult = customerContactProfiles.map(rep => {
    //   let matchingElement = getters.getCustomerRepresentativeProfiles.find(profile => rep.firstName === profile.firstName && rep.lastName === profile.lastName)
    //
    //   if (matchingElement) {
    //     return {
    //       ...rep,
    //       ...matchingElement
    //     }
    //   } else {
    //     return rep
    //   }
    // })
    //
    // // profiles that are not contacts
    // getters.getCustomerRepresentativeProfiles.forEach(profile => {
    //   const exists = mergedResult.some(item => item.firstName === profile.firstName && item.lastName === profile.lastName)
    //   if (!exists) {
    //     let customerProfileObject = {
    //       contactId: null,
    //       customerRepresentativeId: profile.customerRepresentativeId,
    //       name: `${profile.firstName} ${profile.lastName}`,
    //       signature: profile.signature,
    //       firstName: profile.firstName,
    //       lastName: profile.lastName,
    //       email: null,
    //       isPrimary: false,
    //       jobId: getters.getCurrentJob.id // this is our problem
    //     }
    //     mergedResult.push(customerProfileObject)
    //   }
    // })
    //
    // return mergedResult
  // },

  // payments
  getJobReceiptPayments(state) {
    return state._jobReceiptPayments?.slice() ?? []
  },

  // job details
  getCurrentJob(state) {
    return state.currentJob ?? {}
  },
  getCurrentJobScheduledDateOrToday(state) {
    return formatAsDateOnly(state.currentJob?.scheduledDate)
  },
  getIsJobStatusCreated(state) {
    return state.currentJob?.jobStatusId === JobStatus.CREATED.id
  },
  getIsJobStatusScheduled(state) {
    return state.currentJob?.jobStatusId === JobStatus.SCHEDULED.id
  },
  getIsJobStatusClaimed(state) {
    return state.currentJob?.jobStatusId === JobStatus.CLAIMED.id
  },
  getIsJobStatusCompleted(state) {
    return state.currentJob?.jobStatusId === JobStatus.COMPLETED.id
  },
  getIsJobStatusCanceled(state) {
    return state.currentJob?.jobStatusId === JobStatus.CANCELLED.id
  },
  getIsJobStatusClosed(state) {
    return state.currentJob?.jobStatusId === JobStatus.CLOSED.id
  },
  getIsJobStatusAbandoned(state) {
    return state.currentJob?.jobStatusId === JobStatus.ABANDONED.id
  },
  getIsJobFieldsDisabled(state, getters) {
    return getters.getIsJobStatusCanceled || getters.getIsJobStatusClosed || getters.getIsJobStatusAbandoned
  },
  getJobCompletionStatuses(state) {
    return state.jobCompletionStatuses.slice().filter(jobCompletionStatus => jobCompletionStatus.jobId === state.currentJob?.id) ?? []
  },
  getAbandonmentReasons(state) {
    return state.abandonmentReasons.slice() ?? []
  },
  getIsIndirectLaborFabDisabled(state, getters) {
    return !(getters.getIsJobStatusCanceled || getters.getIsJobStatusClosed)
  },
  getJobStatuses(state) {
    return state.jobStatuses?.slice()
  },
  getCancellationReasons(state) {
    return state.cancellationReasons.slice() ?? []
  },
  getJobNotesOrderedByCreatedDate(state) {
    return state.jobNotes
      ?.slice()
      .filter(jn => jn.jobId === state.currentJob?.id)
      .sort((a, b) => new dayjs(b.createdDate) - new dayjs(a.createdDate))
  },
  getAddressesOnJob(state) {
    return state.jobAddresses?.slice().filter(address => address.jobId === state.currentJob?.id)
  },

  // customer contacts
  getAllCustomerContactAddresses(state) {
    return (
      state.customerContactsWithAddresses?.addressDTOs
        ?.slice()
        .sort((a, b) => (a.addressLine1 > b.addressLine1 ? 1 : a.addressLine1 === b.addressLine1 ? (a?.addressLine2 > b?.addressLine2 ? 1 : -1) : -1)) ?? []
    )
  },
  getCustomerContactsWithAddresses(state) {
    return state.customerContactsWithAddresses
  },
  getPrimaryContactDetails(state) {
    return state.customerContactsWithAddresses?.contactDTOs?.find(contact => contact.isPrimary === true) ?? {}
  },

  // operating units, franchises and satellite offices
  getJobSelectedOperatingUnitId(state) {
    return state.currentJob?.operatingUnitId
  },
  getJobSelectedOperatingUnitUsersNotOnJob(state) {
    return state.activeEmployees?.slice().filter(employee => !state.jobEmployees?.some(jobEmployee => employee.id === jobEmployee.employeeId)) ?? []
  },
  getActiveFranchiseCapacities(state) {
    return state.activeFranchiseCapacities?.slice() ?? []
  },
  getSelectedFranchise(state, getters, rootState, rootGetters) {
    return rootGetters.getUserActiveFranchises?.slice().find(franchise => {
      return franchise.franchiseId === state.currentJob?.franchiseId
    }) ?? {}
  },
  getFranchisesInOperatingUnit(state) {
    return state.franchisesInOperatingUnit?.slice() ?? []
  },
  getFranchiseSatelliteOfficesInOperatingUnit(state) {
    return state.franchiseSatelliteOffices?.slice() ?? []
  },
  getFranchiseSatelliteOfficesInFranchise(state) {
    return state.franchiseSatelliteOffices?.slice().filter(satelliteOffice => {
      return satelliteOffice.franchiseId === state.currentJob?.franchiseId
    })
  },
  getFranchiseDispatchAddresses(state) {
    const pastFranchiseAddressesOnJob = state.jobAddresses
        ?.slice()
        .filter(address => address.jobId === state.currentJob?.id && address.isFranchiseLocation === true)
        .map(ja => {
          return {
            id: ja.addressId,
            addressLine1: ja.addressLine1,
            addressLine2: ja.addressLine2,
            city: ja.city,
            zipCode: ja.zipCode,
            stateOrProvince: ja.stateOrProvince,
            country: ja.country,
            isUserValidated: ja.isUserValidated,
            latitude: ja.latitude,
            longitude: ja.longitude
          }
        })

    // todo: Add OperatingUnitId to franchiseSatelliteOffices,
    // todo: thus allowing us to filter out all satellites addresses that are not associated to the job's operating unit
    // todo: .filter(satelliteOffice => satelliteOffice.operatingUnitId === state.currentJob.operatingUnitId)
    let allAddresses = state.franchisesInOperatingUnit
        ?.slice()
        .filter(franchise => franchise.operatingUnitId === state.currentJob?.operatingUnitId)
        .map(franchise => franchise.addressDTO)
        .concat(
            state.franchiseSatelliteOffices.map(satelliteOffice => satelliteOffice.addressDTO),
            pastFranchiseAddressesOnJob
        )

    return [...new Map(allAddresses.map(address => [address.id, address])).values()]
  },
  getSelectedSatelliteOffice(state) {
    return state.franchiseSatelliteOffices?.slice()
        .find(franchiseSatelliteOffice => {
          return franchiseSatelliteOffice.id === state.currentJob?.franchiseSatelliteOfficeId
        }) ?? {}
  },

  // taxes
  getActiveFranchiseTaxes(state) {
    return state.activeFranchiseTaxes?.slice().filter(aft => aft.franchiseId === state.currentJob?.franchiseId) ?? []
  },

  // time slots
  getTimeSlots(state) {
    return state.timeSlots?.slice() ?? []
  },
  getScheduleSlotAllocations(state) {
    return state.scheduleSlotAllocations?.slice() ?? []
  },
  getFormattedServiceDateAsDayOfWeekMonthDayYear(state) {
    return dateAsShortenedDayOfWeekMonthAndYear(state.currentJob?.scheduledDate) ?? ""
  },

  // trucks and employees
  getActiveEmployees(state) {
    return state.activeEmployees.slice() ?? []
  },
  getActiveEmployeesNotOnJob(state, getters) {
    return getters.getActiveEmployees?.slice().filter(employee => !state.jobEmployees?.some(jobEmployee => employee.id === jobEmployee.employeeId)) ?? []
  },
  getActiveTrucks(state) {
    return state.activeTrucks?.slice().filter(truck => truck.operatingUnitId === state.currentJob?.operatingUnitId) ?? []
  },
  getActiveTrucksNotOnJob(state, getters) {
    return getters.getActiveTrucks?.slice().filter(truck => !state.jobTrucks?.some(jobTruck => truck.junkTruckId === jobTruck.truckId)) ?? []
  },
  getEmployeesOnJob(state) {
    return state.jobEmployees?.slice().filter(jobEmployee => jobEmployee.jobId === state.currentJob?.id) ?? []
  },
  getTrucksOnJob(state) {
    return state.jobTrucks?.slice().filter(jobTruck => jobTruck.jobId === state.currentJob?.id) ?? []
  },
  getTrucksAndEmployeesGlobalStartTime(state) {
    return state.trucksAndEmployeesGlobalStartTime
  },
  getTrucksAndEmployeesGlobalEndTime(state) {
    return state.trucksAndEmployeesGlobalEndTime
  },
  getTrucksAndEmployeesGlobalDownTime(state) {
    return state.trucksAndEmployeesGlobalDownTime
  },

  // loaders
  getIsLoadingAnyJobComponents(state) {
    return (
        state.isLoadingJob ||
        state.isLoadingCustomerContactsWithAddresses ||
        state.isLoadingFranchiseAndSatelliteOffices ||
        state.isLoadingFranchiseCapacities ||
        state.isLoadingJobAddressesReorder ||
        state.isLoadingJobAddresses ||
        state.isLoadingJobDetails ||
        state.isLoadingJobModifiers ||
        state.isLoadingJobNotes ||
        state.isLoadingJobTrucksAndEmployees ||
        state._isLoadingJobDocuments ||
        state._isLoadingJobLeadSource ||
        state._isLoadingDiscount
    )
  },
  getIsLoadingEmployeeTips(state) {
    return state._isLoadingEmployeeTips ?? false
  },
  getIsLoadingTipConfiguration(state) {
    return state._isLoadingTipConfiguration
  },
  getTipConfiguration(state) {
    return state._tipConfiguration
  },
  getIsLoadingJob(state) {
    return state.isLoadingJob ?? false
  },
  getIsLoadingEditContact(state) {
    return state.isLoadingEditContact
  },
  getIsLoadingDeleteCustomerAddress(state) {
    return state.isLoadingDeleteCustomerAddress
  },
  getIsLoadingDeletingContact(state) {
    return state._isLoadingDeleteContact
  },
  getIsLoadingCustomerContactsWithAddresses(state) {
    return state.isLoadingCustomerContactsWithAddresses ?? false
  },
  getIsLoadingFranchiseAndSatelliteOffices(state) {
    return state.isLoadingFranchiseAndSatelliteOffices ?? false
  },
  getIsLoadingFranchiseCapacities(state) {
    return state.isLoadingFranchiseCapacities ?? false
  },
  getIsLoadingJobAddressesReorder(state) {
    return state.isLoadingJobAddressesReorder ?? false
  },
  getIsLoadingJobAddresses(state) {
    return state.isLoadingJobAddresses ?? false
  },
  getIsLoadingJobDetails(state) {
    return state.isLoadingJobDetails ?? false
  },
  getIsLoadingJobModifiers(state) {
    return state.isLoadingJobModifiers ?? false
  },
  getIsLoadingJobNotes(state) {
    return state.isLoadingJobNotes ?? false
  },
  getIsLoadingTrucksAndEmployees(state) {
    return state.isLoadingJobTrucksAndEmployees ?? false
  },
  getIsLoadingJobFullScreen(state) {
    return state.isLoadingJobFullScreen ?? { isLoading: false, loadingText: "" }
  },
  getIsLoadingCustomerAddresses(state) {
    return state._isLoadingCustomerAddresses ?? false
  },
  getIsLoadingJobDocuments(state) {
    return state._isLoadingJobDocuments ?? false
  },
  getIsLoadingCreateContact(state) {
    return state.isLoadingCreateContact ?? false
  },
  getIsLoadingUpdateCustomerDetails(state) {
    return state.isLoadingUpdateCustomerDetails ?? false
  },
  getIsLoadingUpdateCustomerAddress(state) {
    return state.isLoadingUpdateCustomerAddress ?? false
  },
  getIsLoadingCustomerRepresentativeProfile(state) {
    return state._isLoadingCustomerRepresentativeProfile ?? false
  },
  getIsLoadingPhrase(state) {
    return state._isLoadingPhrase
  },
  getIsLoadingAddPayment(state) {
    return state._isLoadingAddPayment
  },
  getIsLoadingJobLeadSource(state) {
    return state._isLoadingJobLeadSource
  },
  getIsLoadingResendConfirmationEmail(state) {
    return state._isLoadingResendConfirmationEmail
  }
}

export const mutations = {
  // discounts
  SET_DISCOUNTS(state, discounts) {
    state._activeDiscounts.splice(0, state._activeDiscounts?.length ?? 0, ...discounts)
  },
  REMOVE_INAPPLICABLE_DISCOUNTS(state, franchiseId) {
    state._activeDiscounts = state._activeDiscounts.filter(d => d.franchiseId === franchiseId)
  },
  SET_IS_LOADING_DISCOUNT(state, isLoading) {
    state._isLoadingDiscount = isLoading
  },

  // receipts
  SET_JOB_DOCUMENT_RECEIPT_INFO(state, jobDocumentReceiptInfo) {
    state._jobDocumentReceiptInfo = jobDocumentReceiptInfo
  },
  SET_JOB_DOCUMENT_PHRASES(state, phrases) {
    state._jobDocumentReceiptPhrases = [...phrases]
  },

  // employee profiles
  ADD_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE(state, profile) {
    state._jobDocumentPhraseEmployeeProfiles.push(profile)
  },
  CLEAR_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILES(state) {
    state._jobDocumentPhraseEmployeeProfiles = []
  },
  SET_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE(state, profile) {
    state._jobDocumentPhraseEmployeeProfile = profile
  },
  CLEAR_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE(state) {
    state._jobDocumentPhraseEmployeeProfile = {}
  },

  // phrases
  SET_PHRASE_CHECKBOX_KEY(state) {
    state._phraseCheckboxKey = state._phraseCheckboxKey === 0 ? 1 : 0
  },

  // customer representative signature profiles
  SET_CUSTOMER_REPRESENTATIVE_PROFILES(state, profiles) {
    state._customerRepresentativeProfiles = profiles
  },
  ADD_CUSTOMER_REPRESENTATIVE_PROFILE(state, profile) {
    state._customerRepresentativeProfiles.push(profile)
  },
  CLEAR_CUSTOMER_REPRESENTATIVE_PROFILES(state) {
    state._customerRepresentativeProfiles = []
  },

  // payments
  SET_JOB_RECEIPT_PAYMENTS(state, payments) {
    state._jobReceiptPayments = payments
  },
  ADD_JOB_RECEIPT_PAYMENT(state, payment) {
    state._jobReceiptPayments.push(payment)
  },
  REMOVE_PAYMENT_BY_ID(state, paymentId) {
    let index = state._jobReceiptPayments.findIndex(p => p.jobFranchiseDocumentPaymentId === paymentId)
    if (~index) {
      state._jobReceiptPayments.splice(index, 1)
    }
  },

  // franchises
  SET_ACTIVE_FRANCHISES_IN_OPERATING_UNIT(state, activeFranchisesInOperatingUnit) {
    state.franchisesInOperatingUnit = activeFranchisesInOperatingUnit
  },
  SET_ACTIVE_FRANCHISE_CAPACITIES(state, activeFranchiseCapacities) {
    state.activeFranchiseCapacities = activeFranchiseCapacities
  },
  SET_ACTIVE_FRANCHISE_SATELLITE_OFFICES_IN_OPERATING_UNIT(state, activeFranchiseSatelliteOfficesInOperatingUnit) {
    state.franchiseSatelliteOffices = activeFranchiseSatelliteOfficesInOperatingUnit
  },

  // contacts
  SET_CUSTOMER_CONTACTS(state, customerContacts) {
    state.customerContactsWithAddresses.contactDTOs.splice(0, state.customerContactsWithAddresses.contactDTOs?.length ?? 0, ...customerContacts)
  },
  ADD_CUSTOMER_CONTACT(state, customerContact) {
    state.customerContactsWithAddresses.contactDTOs.push(customerContact)
  },
  REMOVE_ADDRESS_BY_ID(state, addressId) {
    state.customerContactsWithAddresses.addressDTOs = state.customerContactsWithAddresses.addressDTOs.filter(s => {
      return s.id !== addressId
    })
  },
  DELETE_CONTACT(state, id) {
    let index = state.customerContactsWithAddresses.contactDTOs.findIndex(c => c.id === id)
    if (~index) {
      state.customerContactsWithAddresses.contactDTOs.splice(index, 1)
    }
  },
  UPDATE_CUSTOMER_DETAILS(state, customerDetails) {
    state.customerContactsWithAddresses = Object.assign(
      {},
      {
        ...state.customerContactsWithAddresses,
        businessName: customerDetails.businessName,
        isTaxExempt: customerDetails.isTaxExempt,
        isBusiness: customerDetails.isBusiness,
        employerIdentificationNumber: customerDetails.employerIdentificationNumber,
        isCharity: customerDetails.isCharity
      }
    )
  },
  SET_CUSTOMER_ADDRESSES(state, addresses) {
    state.customerContactsWithAddresses.addressDTOs = addresses
  },
  ADD_CUSTOMER_ADDRESS(state, address) {
    state.customerContactsWithAddresses.addressDTOs.push(address)
  },
  SET_LEAD_SOURCES(state, leadSources){
    state._leadSources = leadSources
  },
  SET_CUSTOMER_CONTACTS_WITH_ADDRESSES(state, customerContactsWithAddresses) {
    state.customerContactsWithAddresses = customerContactsWithAddresses
  },
  SET_CURRENT_JOB(state, job) {
    state.currentJob = { ...job }
  },

  // loaders
  SET_IS_LOADING_DELETE_CUSTOMER_ADDRESS(state, isLoading) {
    state.isLoadingDeleteCustomerAddress = isLoading
  },
  SET_IS_LOADING_CUSTOMER_ADDRESSES(state, isLoading) {
    state._isLoadingCustomerAddresses = isLoading
  },
  SET_IS_LOADING_DELETE_CONTACT(state, isDeleting) {
    state._isLoadingDeleteContact = isDeleting
  },
  SET_IS_LOADING_CREATE_CONTACT(state, isLoading) {
    state.isLoadingCreateContact = isLoading
  },
  SET_IS_LOADING_UPDATE_CUSTOMER_DETAILS(state, isLoading) {
    state.isLoadingUpdateCustomerDetails = isLoading
  },
  SET_IS_LOADING_UPDATE_CUSTOMER_ADDRESS(state, isLoading) {
    state.isLoadingUpdateCustomerAddress = isLoading
  },
  SET_IS_LOADING_CUSTOMER_CONTACTS_WITH_ADDRESSES(state, isLoading) {
    state.isLoadingCustomerContactsWithAddresses = isLoading
  },
  SET_IS_LOADING_FRANCHISE_CAPACITIES(state, isLoading) {
    state.isLoadingFranchiseCapacities = isLoading
  },
  SET_IS_LOADING_EDIT_CONTACT(state, isLoading) {
    state.isLoadingEditContact = isLoading
  },
  SET_IS_LOADING_JOB(state, isLoading) {
    state.isLoadingJob = isLoading
  },
  SET_IS_LOADING_JOB_FULL_SCREEN(state, { isLoading, loadingText }) {
    state.isLoadingJobFullScreen = { isLoading: isLoading, loadingText: loadingText }
  },
  RESET_IS_LOADING_JOB_FULL_SCREEN(state) {
    state.isLoadingJobFullScreen = { isLoading: false, loadingText: "" }
  },
  SET_IS_LOADING_JOB_DETAILS(state, isLoading) {
    state.isLoadingJobDetails = isLoading
  },
  SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES(state, isLoading) {
    state.isLoadingFranchiseAndSatelliteOffices = isLoading
  },
  SET_IS_LOADING_JOB_MODIFIERS(state, isLoading) {
    state.isLoadingJobModifiers = isLoading
  },
  SET_IS_LOADING_JOB_ADDRESSES(state, isLoading) {
    state.isLoadingJobAddresses = isLoading
  },
  SET_IS_LOADING_JOB_ADDRESSES_REORDER(state, isLoading) {
    state.isLoadingJobAddressesReorder = isLoading
  },
  SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES(state, isLoading) {
    state.isLoadingJobTrucksAndEmployees = isLoading
  },

  SET_IS_LOADING_EMPLOYEE_TIPS(state, isLoading) {
    state._isLoadingEmployeeTips = isLoading
  },
  SET_IS_LOADING_TIP_CONFIGURATION(state, isLoading) {
    state._isLoadingTipConfiguration = isLoading
  },
  SET_TIP_CONFIGURATION(state, tipConfiguration) {
    state._tipConfiguration = Object.assign({}, tipConfiguration)
  },

  SET_IS_LOADING_JOB_LEAD_SOURCE(state, isLoading){
    state._isLoadingJobLeadSource = isLoading
  },
  SET_JOB_ADDRESSES(state, jobAddresses) {
    state.jobAddresses = jobAddresses
  },
  SET_ACTIVE_TAXES_IN_FRANCHISE(state, activeFranchiseTaxes) {
    state.activeFranchiseTaxes = activeFranchiseTaxes
  },
  SET_TIME_SLOTS(state, timeSlots) {
    state.timeSlots = timeSlots
  },
  SET_JOB_STATUSES(state, jobStatuses) {
    state.jobStatuses = jobStatuses
  },
  SET_JOB_COMPLETION_STATUSES(state, jobCompletionStatuses) {
    state.jobCompletionStatuses = jobCompletionStatuses
  },
  SET_SCHEDULE_SLOT_ALLOCATIONS(state, scheduleSlotAllocations) {
    state.scheduleSlotAllocations = scheduleSlotAllocations
  },
  SET_JOB_NOTES(state, jobNotes) {
    state.jobNotes = jobNotes
  },
  ADD_JOB_NOTE(state, jobNote) {
    state.jobNotes.push(jobNote)
  },
  UPDATE_JOB_NOTE(state, jobNote) {
    let index = state.jobNotes.findIndex(jn => jn.id === jobNote.id && jn.noteType === JobNoteType.JOB_NOTE)
    if (~index) {
      state.jobNotes.splice(index, 1, jobNote)
    }
  },
  SET_ABANDONMENT_REASONS(state, abandonmentReasons) {
    state.abandonmentReasons = abandonmentReasons
  },
  SET_CANCELLATION_REASONS(state, cancellationReasons) {
    state.cancellationReasons = cancellationReasons
  },
  SET_IS_LOADING_JOB_NOTES(state, isLoading) {
    state.isLoadingJobNotes = isLoading
  },
  SET_ACTIVE_EMPLOYEES(state, activeEmployees) {
    state.activeEmployees = activeEmployees
  },
  SET_ACTIVE_TRUCKS(state, activeTrucks) {
    state.activeTrucks = activeTrucks
  },
  SET_JOB_EMPLOYEES(state, jobEmployees) {
    state.jobEmployees = jobEmployees
  },
  SET_JOB_TRUCKS(state, jobTrucks) {
    state.jobTrucks = jobTrucks
  },
  SET_TRUCKS_AND_EMPLOYEES_GLOBAL_START_TIME(state, timeEvent) {
    state.trucksAndEmployeesGlobalStartTime = timeEvent
  },
  SET_TRUCKS_AND_EMPLOYEES_GLOBAL_END_TIME(state, timeEvent) {
    state.trucksAndEmployeesGlobalEndTime = timeEvent
  },
  SET_TRUCKS_AND_EMPLOYEES_GLOBAL_DOWN_TIME(state, value) {
    state.trucksAndEmployeesGlobalDownTime = value
  },
  UPDATE_JOB_DOCUMENT_PHRASE(state, jobDocumentPhrase) {
    let index = state._jobDocumentReceiptPhrases.findIndex(jdp => jdp.jobFranchiseDocumentPhraseId === jobDocumentPhrase.jobFranchiseDocumentPhraseId)
    if (~index) {
      state._jobDocumentReceiptPhrases.splice(index, 1, jobDocumentPhrase)
    }
  },
  SET_IS_LOADING_JOB_DOCUMENTS(state, isLoading) {
    state._isLoadingJobDocuments = isLoading
  },
  SET_IS_LOADING_PHRASE(state, isLoading) {
    state._isLoadingPhrase = isLoading
  },
  SET_IS_LOADING_CUSTOMER_REPRESENTATIVE_PROFILE(state, isLoading) {
    state._isLoadingCustomerRepresentativeProfile = isLoading
  },
  SET_IS_LOADING_ADD_PAYMENT(state, isLoading) {
    state._isLoadingAddPayment = isLoading
  },
  SET_ALL_ELIGIBLE_RECEIPT_RECIPIENTS(state, allEligibleReceiptRecipients) {
    state._allEligibleReceiptRecipients = allEligibleReceiptRecipients
  },
  SET_SELECTED_EMAIL_RECEIPT_RECIPIENTS(state, selectedEmailReceiptRecipients) {
    state._selectedEmailReceiptRecipients = selectedEmailReceiptRecipients
  },
  ADD_ELIGIBLE_RECEIPT_RECIPIENT(state, recipient) {
    state._allEligibleReceiptRecipients.push(recipient)
  },
  ADD_SELECTED_EMAIL_RECEIPT_RECIPIENT(state, recipient) {
    state._selectedEmailReceiptRecipients.push(recipient)
  },
  REMOVE_SELECTED_EMAIL_RECEIPT_RECIPIENT(state, recipient) {
    let index = state._selectedEmailReceiptRecipients.findIndex(r => r.listId === recipient.listId)
    if (~index) {
      state._selectedEmailReceiptRecipients.splice(index, 1)
    }
  },
  SET_IS_LOADING_RESEND_CONFIRMATION_EMAIL(state, isLoading) {
    state._isLoadingResendConfirmationEmail = isLoading
  },
  SET_IS_SINGLE_ITEM(state, isSingleItem) {
    state.currentJob.isSingleItem = isSingleItem
  }
}

export const actions = {
  async clearJobState({ commit }) {
    commit("SET_SELECTED_EMAIL_RECEIPT_RECIPIENTS", [])
    commit("SET_ALL_ELIGIBLE_RECEIPT_RECIPIENTS", [])
    commit("SET_JOB_RECEIPT_PAYMENTS", [])
    commit("CLEAR_CUSTOMER_REPRESENTATIVE_PROFILES")
    commit("CLEAR_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILES")
    commit("CLEAR_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE")
    commit("SET_JOB_DOCUMENT_PHRASES", [])
    commit("SET_JOB_DOCUMENT_RECEIPT_INFO", {})
    commit("SET_JOB_NOTES", [])
    commit("SET_JOB_ADDRESSES", [])
    commit("SET_ACTIVE_FRANCHISE_CAPACITIES", [])
    commit("SET_ACTIVE_TAXES_IN_FRANCHISE", [])
    commit("SET_ACTIVE_EMPLOYEES", [])
    commit("SET_ACTIVE_TRUCKS", [])
    commit("SET_ACTIVE_FRANCHISES_IN_OPERATING_UNIT", [])
    commit("SET_ACTIVE_FRANCHISE_SATELLITE_OFFICES_IN_OPERATING_UNIT", [])
    commit("SET_TIME_SLOTS", [])
    commit("SET_JOB_COMPLETION_STATUSES", [])
    commit("SET_CUSTOMER_CONTACTS_WITH_ADDRESSES", {})
    commit("SET_DISCOUNTS", [])
  },

  // discounts
  async fetchApplicableDiscounts({ commit }, jobId) {
    commit("SET_IS_LOADING_DISCOUNT", true)
    await junkApi
      .fetchDiscountsByJobId(jobId)
      .then(discounts => {
        commit("SET_DISCOUNTS", discounts)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DISCOUNT", false)
      })
  },
  async applyJobDiscount({ commit }, putJobDiscountDto) {
    commit("SET_IS_LOADING_DISCOUNT", true)
    await junkApi
      .applyDiscountToJob(putJobDiscountDto)
      .then(response => {
        commit("SET_CURRENT_JOB", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DISCOUNT", false)
      })
  },
  async removeJobDiscount({ commit, getters }, userId) {
    commit("SET_IS_LOADING_DISCOUNT", true)
    const deleteJobDiscountDto = {
      jobId: getters.getCurrentJob.id,
      modifiedBy: userId
    }
    await junkApi
      .removeDiscountFromJob(deleteJobDiscountDto)
      .then(job => {
        commit("SET_CURRENT_JOB", job)
        commit("REMOVE_INAPPLICABLE_DISCOUNTS", job.franchiseId)
        return Promise.resolve(job)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DISCOUNT", false)
      })
  },
  async addEligibleReceiptRecipient({ commit }, recipient) {
    commit("ADD_ELIGIBLE_RECEIPT_RECIPIENT", recipient)
  },
  async addSelectedEmailReceiptRecipient({ commit }, recipient) {
    commit("ADD_SELECTED_EMAIL_RECEIPT_RECIPIENT", recipient)
  },
  async removeSelectedEmailReceiptRecipient({ commit }, recipient) {
    commit("REMOVE_SELECTED_EMAIL_RECEIPT_RECIPIENT", recipient)
  },

  // receipts
  async createJobReceipt({ commit }, createJobDocumentReceiptDto) {
    commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
    commit("SET_JOB_DOCUMENT_RECEIPT_INFO", {})
    commit("SET_JOB_DOCUMENT_PHRASES", [])
    return await documentApi
      .postJobReceiptToCreate(createJobDocumentReceiptDto)
      .then(data => {
        if (data.receiptInfoDto) {
          commit("SET_JOB_DOCUMENT_RECEIPT_INFO", data.receiptInfoDto)
        }
        if (data.jobFranchiseDocumentPhraseDtos) {
          const phraseDtos = data.jobFranchiseDocumentPhraseDtos.map(phrase => {
            return { ...phrase, isSavedToServer: true }
          })
          commit("SET_JOB_DOCUMENT_PHRASES", phraseDtos)
        }
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
      })
  },
  async fetchJobReceipt({ commit, getters }) {
    commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
    commit("SET_JOB_DOCUMENT_RECEIPT_INFO", {})
    commit("SET_JOB_DOCUMENT_PHRASES", [])
    return await documentApi
      .fetchJobReceipt(getters.getCurrentJob?.id)
      .then(data => {
        if (data.receiptInfoDto) {
          commit("SET_JOB_DOCUMENT_RECEIPT_INFO", data.receiptInfoDto)
        }
        if (data.jobFranchiseDocumentPhraseDtos) {
          const phraseDtos = data.jobFranchiseDocumentPhraseDtos.map(phrase => {
            return { ...phrase, isSavedToServer: true }
          })
          commit("SET_JOB_DOCUMENT_PHRASES", phraseDtos)
        }
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
      })
  },
  async generateJobReceipt({ commit }, generateJobReceiptDto) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Generating Statement..." })
    return await documentApi
      .postJobReceiptToGenerate(generateJobReceiptDto)
      .then(response => {
        commit("SET_JOB_DOCUMENT_RECEIPT_INFO", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async resendJobReceipt({ commit }, resendJobReceiptDto) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Sending Receipt..." })
    return await documentApi
      .postJobReceiptToResend(resendJobReceiptDto)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },

  // recipients
  async setAllEligibleReceiptRecipients({ commit }, allEligibleReceiptRecipients) {
    commit("SET_ALL_ELIGIBLE_RECEIPT_RECIPIENTS", allEligibleReceiptRecipients)
  },
  async setSelectedEmailReceiptRecipients({ commit }, allEligibleReceiptRecipients) {
    commit("SET_SELECTED_EMAIL_RECEIPT_RECIPIENTS", allEligibleReceiptRecipients)
  },

  // employee profiles
  async createJobDocumentEmployeeProfile({ commit }, createEmployeeProfileDto) {
    commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
    await documentApi
      .postJobDocumentEmployeeProfileToCreate(createEmployeeProfileDto)
      .then(data => {
        commit("ADD_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE", data)
        commit("SET_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE", data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Creating Employee Profile ${errorMessageHandler(error)}}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
      })
  },
  async fetchJobDocumentEmployeeProfileByEmployeeId({ commit, dispatch }, { employeeId, firstName, lastName }) {
    commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
    await documentApi
      .fetchJobDocumentEmployeeProfileByEmployeeId(employeeId)
      .then(data => {
        if (data !== "" && data !== undefined && data !== null) {
          commit("ADD_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE", data)
          commit("SET_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE", data)
        } else {
          const createEmployeeProfileDto = {
            employeeProfileId: v4(),
            employeeId: employeeId,
            firstName: firstName,
            lastName: lastName
          }
          dispatch("createJobDocumentEmployeeProfile", createEmployeeProfileDto)
        }
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Fetching Employee Profile ${errorMessageHandler(error)}}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
      })
  },
  async fetchJobDocumentEmployeeProfilesFromMarkedPhrases({ commit, getters }) {
    for (const phrase of getters.getJobDocumentReceiptPhrases) {
      if (phrase.employeeProfileId) {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
        await documentApi
          .fetchJobDocumentEmployeeProfileByProfileId(phrase.employeeProfileId)
          .then(data => {
            if (data) {
              commit("ADD_JOB_DOCUMENT_PHRASE_EMPLOYEE_PROFILE", data)
            }
          })
          .catch(error => {
            commit("ADD_SNACKBAR", errorSnackbar(`Error Fetching Employee Profiles ${errorMessageHandler(error)}}`), { root: true })
            Sentry.captureException(error)
            return Promise.reject(error)
          })
          .finally(() => {
            commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
          })
      }
    }
  },

  // util
  async setPhraseCheckboxKey({ commit }) {
    commit("SET_PHRASE_CHECKBOX_KEY")
  },

  // customer representative signature profiles
  async fetchJobDocumentCustomerRepresentativeSignatureProfilesByJobId({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
    await documentApi
      .fetchCustomerRepresentativeSignatureProfilesByJobId(jobId)
      .then(data => {
        commit("SET_CUSTOMER_REPRESENTATIVE_PROFILES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Fetching Customer Representatives ${errorMessageHandler(error)}}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
      })
  },
  async clearCustomerRepresentativeProfiles({ commit }) {
    commit("CLEAR_CUSTOMER_REPRESENTATIVE_PROFILES")
  },
  async saveJobDocumentCustomerRepresentativeSignatureProfile({ commit }, profile) {
    commit("SET_IS_LOADING_CUSTOMER_REPRESENTATIVE_PROFILE", true)
    return await documentApi
      .postCustomerRepresentativeSignatureProfile(profile)
      .then(data => {
        commit("ADD_CUSTOMER_REPRESENTATIVE_PROFILE", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Saving Customer Representative Signature ${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_CUSTOMER_REPRESENTATIVE_PROFILE", false)
      })
  },

  // payments
  async fetchJobReceiptPayments({ commit }, jobFranchiseDocumentId) {
    commit("SET_IS_LOADING_JOB_DOCUMENTS", true)
    await documentApi
      .fetchJobReceiptPayments(jobFranchiseDocumentId)
      .then(data => {
        commit("SET_JOB_RECEIPT_PAYMENTS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Fetching Payments ${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DOCUMENTS", false)
      })
  },
  async addJobReceiptPayment({ commit }, payment) {
    commit("SET_IS_LOADING_ADD_PAYMENT", true)
    await documentApi
      .postJobReceiptPaymentToSave(payment)
      .then(data => {
        commit("ADD_JOB_RECEIPT_PAYMENT", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Adding Payment! ${error}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_ADD_PAYMENT", false)
      })
  },
  async deleteJobReceiptPayment({ commit }, paymentId) {
    commit("SET_IS_LOADING_ADD_PAYMENT", true)
    await documentApi
      .deleteJobReceiptPayment(paymentId)
      .then(() => {
        commit("REMOVE_PAYMENT_BY_ID", paymentId)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Deleting Payment ${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_ADD_PAYMENT", false)
      })
  },
  async updateJobDocumentPhrase({ commit }, updatedJobDocumentPhrase) {
    commit("UPDATE_JOB_DOCUMENT_PHRASE", updatedJobDocumentPhrase)
  },
  async saveJobDocumentPhrase({ commit }, jobDocumentPhrase) {
    commit("SET_IS_LOADING_PHRASE", true)
    await documentApi
      .postJobDocumentPhraseToUpdate(jobDocumentPhrase)
      .then(response => {
        const updatedPhrase = { ...response, isSavedToServer: true }
        commit("UPDATE_JOB_DOCUMENT_PHRASE", updatedPhrase)
        return Promise.resolve(updatedPhrase)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Saving Job Document Phrase! ${error}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_PHRASE", false)
      })
  },
  async setEmployeesInJobOperatingUnit({ commit }, users) {
    commit("SET_ACTIVE_EMPLOYEES", users)
  },
  async putLeadSource({ commit }, putLeadSourceDto) {
    commit("SET_IS_LOADING_JOB_LEAD_SOURCE", true)
    return await junkApi
      .putLeadSource(putLeadSourceDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error setting job lead source: ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_LEAD_SOURCE", false)
      })
  },
  async fetchLeadSources({ commit }) {
    commit("SET_IS_LOADING_JOB_LEAD_SOURCE", true)
    return await junkApi
      .fetchLeadSources()
      .then(data => {
        commit("SET_LEAD_SOURCES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error fetching job lead sources: ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_LEAD_SOURCE", false)
      })
  },
  async setNewCustomerAddress({ commit }, address) {
    commit("SET_CUSTOMER_ADDRESSES", address)
  },
  async fetchJunkJobStatuses({ commit }) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .fetchJunkJobStatuses()
      .then(data => {
        commit("SET_JOB_STATUSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Statuses! ${error}`, "Retry", () => this.dispatch("Job/fetchJunkJobStatuses")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async fetchJobCompletionStatuses({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .fetchJobCompletionStatuses(jobId)
      .then(data => {
        commit("SET_JOB_COMPLETION_STATUSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Completion Statuses! ${errorMessage}`, "Retry", () =>
            this.dispatch("Job/fetchJobCompletionStatuses", jobId)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async fetchJunkJobByJobIdInitial({ commit, dispatch }, jobId) {
    dispatch("setAllJobComponentsLoadingState", true)
    commit("SET_CURRENT_JOB", {})
    return await junkApi
      .fetchJunkJobByJobId(jobId)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Job! ${error}`, "Retry", () => this.dispatch("Job/fetchJunkJobByJobIdInitial", jobId)),
          { root: true }
        )
        dispatch("setAllJobComponentsLoadingState", false)
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
        // dispatch("setAllJobComponentsLoadingState", false)
      })
  },
  async fetchJunkJobByJobId({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .fetchJunkJobByJobId(jobId)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Job! ${errorMessage}`, "Retry", () => this.dispatch("Job/fetchJunkJobByJobId", jobId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async fetchAbandonmentReasons({ commit }) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .fetchAbandonmentReasons()
      .then(data => {
        commit("SET_ABANDONMENT_REASONS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Abandonment Reasons! ${errorMessage}`, "Retry", () => this.dispatch("Job/fetchAbandonmentReasons")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async fetchCancellationReasons({ commit }) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .fetchCancellationReasons()
      .then(data => {
        commit("SET_CANCELLATION_REASONS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Cancellation Reasons! ${errorMessage}`, "Retry", () => this.dispatch("Job/fetchCancellationReasons")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async fetchCustomerAddresses({ commit }, customerId) {
    commit("SET_IS_LOADING_CUSTOMER_ADDRESSES", true)
    return await junkApi
      .fetchCustomerAddresses(customerId)
      .then(data => {
        commit("SET_CUSTOMER_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Customer Addresses! ${errorMessage}`, "Retry", () => this.dispatch("Job/fetchCancellationReasons")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_CUSTOMER_ADDRESSES", false)
      })
  },
  async resendConfirmationEmail({ commit, getters }, resendConfirmationEmailDto) {
    commit("SET_IS_LOADING_RESEND_CONFIRMATION_EMAIL", true)
    return await junkApi
      .resendJobConfirmationEmail(getters.getCurrentJob.id, resendConfirmationEmailDto)
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_RESEND_CONFIRMATION_EMAIL", false))
  },
  async scheduleJunkJobByJobId({ commit }, { jobId, scheduleJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Scheduling Job..." })
    return await junkApi
      .scheduleJunkJob(jobId, scheduleJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        commit("UPDATE_JOB_TAB_JOB_NUMBER_BY_JOB_ID", data, { root: true })
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Scheduling Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async abandonJunkJobByJobId({ commit }, { jobId, abandonJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Abandoning Job..." })
    return await junkApi
      .abandonJunkJob(jobId, abandonJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Abandoning Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async cancelJunkJobByJobId({ commit }, { jobId, cancelJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Cancelling Job..." })
    return await junkApi
      .cancelJunkJob(jobId, cancelJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Cancelling Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async closeJunkJobByJobId({ commit }, { jobId, closeJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Closing Job..." })
    return await junkApi
      .closeJunkJob(jobId, closeJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Closing Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async claimJunkJobByJobId({ commit }, { jobId, claimJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Claiming Job..." })
    return await junkApi
      .claimJunkJob(jobId, claimJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Claiming Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async disclaimJunkJobByJobId({ commit }, { jobId, disclaimJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Disclaiming Job..." })
    return await junkApi
      .disclaimJunkJob(jobId, disclaimJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Disclaiming Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async uncompleteJunkJobByJobId({ commit }, uncompleteJobDto) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Uncompleting Job..." })
    return await junkApi
      .uncompleteJunkJob(uncompleteJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Uncompleting Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async completeJunkJobByJobId({ commit }, { jobId, completeJobDto }) {
    commit("SET_IS_LOADING_JOB_FULL_SCREEN", { isLoading: true, loadingText: "Completing Job..." })
    return await junkApi
      .completeJunkJob(jobId, completeJobDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Completing Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("RESET_IS_LOADING_JOB_FULL_SCREEN")
      })
  },
  async createAddressRecords({ commit }, { customerId, createCustomerAddressDto }) {
    commit("SET_IS_LOADING_JOB_ADDRESSES", true)
    return await junkApi
      .addAddress(customerId, createCustomerAddressDto)
      .then(data => {
        commit("ADD_CUSTOMER_ADDRESS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Adding Address! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_ADDRESSES", false)
      })
  },
  async fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits({ commit }, operatingUnitIds) {
    commit("SET_ACTIVE_FRANCHISES_IN_OPERATING_UNIT", [])
    commit("SET_ACTIVE_FRANCHISE_SATELLITE_OFFICES_IN_OPERATING_UNIT", [])
    commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", true)
    return await junkApi
      .fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits(operatingUnitIds)
      .then(data => {
        // filter here?
        commit("SET_ACTIVE_FRANCHISES_IN_OPERATING_UNIT", data.franchiseWithAddressDTOs)
        commit("SET_ACTIVE_FRANCHISE_SATELLITE_OFFICES_IN_OPERATING_UNIT", data.satelliteOfficeWithAddressDTOs)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises and Satellite Offices! ${error}`, "Retry", () =>
            this.dispatch("Job/fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits", operatingUnitIds)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", false)
      })
  },
  async fetchJunkJobNotes({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB_NOTES", true)
    return await junkApi
      .fetchJunkJobNotes(jobId)
      .then(data => {
        commit("SET_JOB_NOTES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Notes! ${error}`, "Retry", () => this.dispatch("Job/fetchJunkJobNotes", jobId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_NOTES", false)
      })
  },
  async createJunkJobNote({ commit }, createJobNoteDto) {
    commit("SET_IS_LOADING_JOB_NOTES", true)
    return await junkApi
      .createJunkJobNote(createJobNoteDto)
      .then(data => {
        commit("ADD_JOB_NOTE", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Creating Note! ${error}`, "Retry", () => this.dispatch("Job/createJunkJobNote", createJobNoteDto)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_NOTES", false)
      })
  },
  async softDeleteJunkJobNote({ commit }, jobNoteId) {
    commit("SET_IS_LOADING_JOB_NOTES", true)
    return await junkApi
      .softDeleteJunkJobNote(jobNoteId)
      .then(jobNote => {
        commit("UPDATE_JOB_NOTE", jobNote)
        return Promise.resolve(jobNote)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_NOTES", false)
      })
  },
  async updateScheduledDate({ commit, dispatch }, jobScheduledDateDto) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .updateJunkJobScheduledDate(jobScheduledDateDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        dispatch("fetchApplicableDiscounts", data.id)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Updating Scheduled Date! ${errorMessage}`, "Retry", () => this.dispatch("Job/updateScheduledDate", jobScheduledDateDto)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async updateSelectedFranchiseAndCapacityByFranchiseIdAndCapacityId({ commit, getters }, putJunkJobDto) {
    commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", true)
    return await junkApi
      .updateJunkJobSelectedFranchise(putJunkJobDto)
      .then(data => {
        if (formatAsDateOnly(data.scheduledDate) !== formatAsDateOnly(getters.getCurrentJobScheduledDateOrToday)) {
          commit("ADD_SNACKBAR", infoSnackbar("Updated Scheduled Date to match Selected Franchise's First Day of Business"), { root: true })
        }
        commit("SET_CURRENT_JOB", data)
        commit("UPDATE_JOB_TAB_JOB_NUMBER_BY_JOB_ID", data, { root: true })
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
            this.dispatch("Job/updateSelectedFranchiseAndCapacityByFranchiseIdAndCapacityId", putJunkJobDto)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", false)
      })
  },
  async updateJunkJobSelectedFranchiseSatelliteOffice({ commit }, putJunkJobSatelliteOfficeDto) {
    commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", true)
    return await junkApi
      .updateJunkJobSelectedFranchiseSatelliteOffice(putJunkJobSatelliteOfficeDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
            this.dispatch("Job/updateJunkJobSelectedFranchiseSatelliteOffice", putJunkJobSatelliteOfficeDto)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", false)
      })
  },
  async updateJunkJobPrices({ commit }, jobPriceDto) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .updateJunkJobPrices(jobPriceDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.dispatch("Job/updateJunkJobPrices", jobPriceDto)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async updateJunkJobSingleItemStatus({ commit }, singleItemDto) {
    commit("SET_IS_LOADING_JOB", true)
    return await junkApi
      .updateJunkJobIsSingleItem(singleItemDto)
      .then(data => {
        commit("SET_IS_SINGLE_ITEM", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.dispatch("Job/updateJunkJobSingleItemStatus", { singleItemDto })),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB", false)
      })
  },
  async deleteCustomerAddress({ commit, getters }, addressId) {
    commit("SET_IS_LOADING_DELETE_CUSTOMER_ADDRESS", true)
    return await junkApi
      .deleteCustomerAddress(getters.getCurrentJob?.customerId, addressId)
      .then(() => {
        commit("REMOVE_ADDRESS_BY_ID", addressId)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DELETE_CUSTOMER_ADDRESS", false))
  },
  async createContact({ commit }, createContactDto) {
    commit("SET_IS_LOADING_CREATE_CONTACT", true)
    return await junkApi
      .createContact(createContactDto)
      .then(customerContact => {
        commit("ADD_CUSTOMER_CONTACT", customerContact)
        return Promise.resolve(customerContact)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_CREATE_CONTACT", false))
  },
  async deleteContact({ commit }, id) {
    commit("SET_IS_LOADING_DELETE_CONTACT", true)
    return await junkApi
      .deleteContact(id)
      .then(data => {
        commit("DELETE_CONTACT", id)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DELETE_CONTACT", false))
  },
  async editContact({ commit, rootGetters }, editContactDto) {
    commit("SET_IS_LOADING_EDIT_CONTACT", true)
    return await junkApi
      .editContact(editContactDto, rootGetters.getUserOperatingUnitIds)
      .then(data => {
        commit("SET_CUSTOMER_CONTACTS", data)
        let primaryContact = data.find(c => c.isPrimary === true)
        commit("UPDATE_JOB_TAB_PRIMARY_CONTACT_BY_CUSTOMER_ID", primaryContact, { root: true })
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Editing Contact ${errorMessageHandler(error)}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_EDIT_CONTACT", false))
  },
  async updateCustomerAddressUserValidated({ commit }, putCustomerAddressUserValidatedDto) {
    commit("SET_IS_LOADING_UPDATE_CUSTOMER_ADDRESS", true)
    return await junkApi
      .updateCustomerAddressUserValidated(putCustomerAddressUserValidatedDto)
      .then(customerAddresses => {
        commit("SET_CUSTOMER_ADDRESSES", customerAddresses)
        return Promise.resolve(customerAddresses)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Customer Address! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => commit("SET_IS_LOADING_UPDATE_CUSTOMER_ADDRESS", false))
  },
  async updateCustomerDetails({ commit }, putCustomerDetailsDto) {
    commit("SET_IS_LOADING_UPDATE_CUSTOMER_DETAILS", true)
    return await junkApi
      .updateCustomerDetails(putCustomerDetailsDto)
      .then(customerDetails => {
        commit("UPDATE_CUSTOMER_DETAILS", customerDetails)
        commit("UPDATE_JOB_TAB_CUSTOMER_BY_CUSTOMER_ID", customerDetails, { root: true })
        return Promise.resolve(customerDetails)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => commit("SET_IS_LOADING_UPDATE_CUSTOMER_DETAILS", false))
  },
  async updateCustomerAddress({ commit }, putCustomerAddressDto) {
    commit("SET_IS_LOADING_UPDATE_CUSTOMER_ADDRESS", true)
    return await junkApi
      .updateCustomerAddress(putCustomerAddressDto)
      .then(customerAddresses => {
        commit("SET_CUSTOMER_ADDRESSES", customerAddresses)
        return Promise.resolve(customerAddresses)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Customer Address! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => commit("SET_IS_LOADING_UPDATE_CUSTOMER_ADDRESS", false))
  },
  async fetchCustomerContactsAndAddresses({ commit, rootGetters }, customerId) {
    commit("SET_IS_LOADING_CUSTOMER_CONTACTS_WITH_ADDRESSES", true)
    commit("SET_CUSTOMER_CONTACTS_WITH_ADDRESSES", {})
    return await junkApi
      .fetchCustomerContactsAndAddresses(customerId, rootGetters.getUserOperatingUnitIds)
      .then(data => {
        commit("SET_CUSTOMER_CONTACTS_WITH_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${error}`, "Retry", () => this.dispatch("Job/fetchCustomerContactsAndAddresses", customerId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_CUSTOMER_CONTACTS_WITH_ADDRESSES", false)
      })
  },
  async fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({ commit }, { franchiseId: franchiseId, date: date }) {
    commit("SET_IS_LOADING_FRANCHISE_CAPACITIES", true)
    commit("SET_ACTIVE_FRANCHISE_CAPACITIES", [])
    return await junkApi
      .fetchActiveFranchiseCapacitiesByFranchiseIdAndDate(franchiseId, date)
      .then(data => {
        commit("SET_ACTIVE_FRANCHISE_CAPACITIES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchise Capacities! ${error}`, "Retry", () =>
            this.dispatch("Job/fetchActiveFranchiseCapacitiesByFranchiseIdAndDate", { franchiseId: franchiseId, date: date })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_CAPACITIES", false)
      })
  },
  async fetchActiveFranchiseTaxes({ commit }, { franchiseId, date }) {
    commit("SET_IS_LOADING_JOB_MODIFIERS", true)
    return await junkApi
      .fetchActiveFranchiseTaxesByFranchiseIdAndDate(franchiseId, date)
      .then(activeFranchiseTaxes => {
        commit("SET_ACTIVE_TAXES_IN_FRANCHISE", activeFranchiseTaxes)
        return Promise.resolve(activeFranchiseTaxes)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.dispatch("Job/fetchActiveFranchiseTaxes", { franchiseId, date })),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_MODIFIERS", false))
  },
  async updateJobSelectedFranchiseTax({ commit }, putJobSelectedFranchiseTaxDto) {
    commit("SET_IS_LOADING_JOB_MODIFIERS", true)
    return await junkApi
      .updateJobSelectedFranchiseTax(putJobSelectedFranchiseTaxDto)
      .then(job => {
        commit("SET_CURRENT_JOB", job)
        return Promise.resolve(job)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
            this.dispatch("Job/updateJobSelectedFranchiseTax", putJobSelectedFranchiseTaxDto)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_MODIFIERS", false))
  },
  async fetchScheduleSlotAllocations({ commit }, getScheduleSlotAllocationsDto) {
    commit("SET_IS_LOADING_JOB_DETAILS", true)
    return await junkApi
      .fetchScheduleSlotAllocations(getScheduleSlotAllocationsDto)
      .then(data => {
        commit("SET_SCHEDULE_SLOT_ALLOCATIONS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Allocations! ${error}`, "Retry", () =>
            this.dispatch("Job/fetchScheduleSlotAllocations", getScheduleSlotAllocationsDto)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_DETAILS", false))
  },
  async fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId({ commit }, { franchiseId, date, preferredTimeSlotId }) {
    const dto = fetchScheduleSlotDto(franchiseId, date, date)
    return await junkApi
      .fetchScheduleSlotAllocations(dto)
      .then(scheduleSlotAllocations => {
        return Promise.resolve(
          (preferredTimeSlotId === 1 && scheduleSlotAllocations[0].firstSlotsRemaining <= 0) ||
          (preferredTimeSlotId === 2 && scheduleSlotAllocations[0].amSlotsRemaining <= 0) ||
          (preferredTimeSlotId === 3 && scheduleSlotAllocations[0].totalPmSlotsRemaining <= 0) ||
          (preferredTimeSlotId === 4 && scheduleSlotAllocations[0].totalSlotsRemaining <= 0)
        )
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Allocations! ${errorMessageHandler(error)}`, "Retry", () =>
            this.dispatch("Job/fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId", { franchiseId, date, preferredTimeSlotId })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
  },
  async fetchTimeSlots({ commit }) {
    commit("SET_IS_LOADING_JOB_DETAILS", true)
    return await junkApi
      .fetchTimeSlots()
      .then(data => {
        commit("SET_TIME_SLOTS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Time Slots! ${error}`, "Retry", () => this.dispatch("Job/fetchTimeSlots")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_DETAILS", false))
  },
  async fetchJunkJobAddresses({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB_ADDRESSES", true)
    return await junkApi
      .fetchJunkJobAddresses(jobId)
      .then(data => {
        commit("SET_JOB_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Addresses on Job! ${error}`, "Retry", () => this.dispatch("Job/fetchJunkJobAddresses", jobId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_ADDRESSES", false))
  },
  async addJunkJobAddresses({ commit, getters }, addresses) {
    commit("SET_IS_LOADING_JOB_ADDRESSES", true)
    return await junkApi
      .addJunkJobAddresses(getters.getCurrentJob?.id, addresses)
      .then(data => {
        commit("SET_JOB_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_ADDRESSES", false)
      })
  },
  async deleteJunkJobAddress({ commit }, jobAddressId) {
    commit("SET_IS_LOADING_JOB_ADDRESSES", true)
    return await junkApi
      .deleteJunkJobAddress(jobAddressId)
      .then(data => {
        commit("SET_JOB_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Job/deleteJunkJobAddress", jobAddressId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_ADDRESSES", false))
  },
  async updateJunkJobAddressStopOrder({ commit }, { jobAddressId, desiredStopOrder }) {
    commit("SET_IS_LOADING_JOB_ADDRESSES_REORDER", true)
    await junkApi
      .updateJunkJobAddressStopOrder(jobAddressId, desiredStopOrder)
      .then(data => {
        commit("SET_JOB_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Adjusting Stop Order! ${error}`, "Retry", () =>
            this.dispatch("Job/updateJunkJobAddressStopOrder", { jobAddressId, desiredStopOrder })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_ADDRESSES_REORDER", false))
  },
  async updateJunkJobStartOrReturnAddress({ commit }, { jobAddressId, addressId }) {
    commit("SET_IS_LOADING_JOB_ADDRESSES", true)
    await junkApi
      .updateJunkJobStartOrReturnAddress(jobAddressId, addressId)
      .then(data => {
        commit("SET_JOB_ADDRESSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Job/updateJunkJobStartOrReturnAddress", { jobAddressId, addressId })),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_ADDRESSES", false))
  },
  async updateJunkJobPreferredTimeSlot({ commit }, putJunkJobPreferredTimeSlotDto) {
    commit("SET_IS_LOADING_JOB_DETAILS", true)
    return await junkApi
      .updateJunkJobPreferredTimeSlot(putJunkJobPreferredTimeSlotDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Job/updateJunkJobPreferredTimeSlot", putJunkJobPreferredTimeSlotDto)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_DETAILS", false))
  },
  async fetchActiveJunkTrucksInOperatingUnitByDate({ commit }, { date, operatingUnitId }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .fetchActiveJunkTrucksInOperatingUnitByDate(date, operatingUnitId)
      .then(data => {
        commit("SET_ACTIVE_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Trucks! ${error}`, "Retry", () =>
            this.dispatch("Job/fetchActiveJunkTrucksInOperatingUnitByDate", { date, operatingUnitId })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false))
  },
  // async fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate({ commit }, { date, operatingUnitId }) {
  //   commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
  //   return await junkApi
  //     .fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate(date, operatingUnitId)
  //     .then(data => {
  //       commit("SET_ACTIVE_EMPLOYEES", data)
  //       return Promise.resolve(data)
  //     })
  //     .catch(error => {
  //       commit(
  //         "ADD_SNACKBAR",
  //         persistentErrorSnackbarWithPositiveAction(`Error Fetching Employees! ${error}`, "Retry", () => this.dispatch("Job/fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate", { date, operatingUnitId })),
  //         { root: true }
  //       )
  //       Sentry.captureException(error)
  //       return Promise.reject(error)
  //     })
  //     .finally(() => commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false))
  // },
  async createJunkJobTrucks({ commit }, { jobId, createJobTruckDto }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .createJunkJobTrucks(jobId, createJobTruckDto)
      .then(data => {
        commit("SET_JOB_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Adding Trucks to Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async deleteJunkJobTruck({ commit }, jobTruckId) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .deleteJunkJobTruck(jobTruckId)
      .then(data => {
        commit("SET_JOB_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Deleting Truck! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async updateJunkJobTruckStartDateTime({ commit }, putJunkJobTruckStartDateTimeDto) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .updateJunkJobTruckStartDateTime(putJunkJobTruckStartDateTimeDto)
      .then(data => {
        commit("SET_JOB_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Truck Start Time! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async updateJunkJobTruckEndDateTime({ commit }, putJunkJobTruckEndDateTimeDto) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .updateJunkJobTruckEndDateTime(putJunkJobTruckEndDateTimeDto)
      .then(data => {
        commit("SET_JOB_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Truck End Time! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async updateJunkJobTruckDownTime({ commit }, updateJunkJobTruckDownTimeDto) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .updateJunkJobTruckDownTime(updateJunkJobTruckDownTimeDto)
      .then(data => {
        commit("SET_JOB_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Truck Down Time! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async createJunkJobEmployees({ commit }, { jobId, createJobEmployeeDto }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .createJunkJobEmployees(jobId, createJobEmployeeDto)
      .then(data => {
        commit("SET_JOB_EMPLOYEES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Adding Employees to Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async createJobEmployeeIndirectLabor({ commit }, { jobId, createIndirectLaborDtos }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .createJobEmployeeIndirectLabor(jobId, createIndirectLaborDtos)
      .then(data => {
        commit("SET_JOB_EMPLOYEES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Adding Indirect Labor to Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async updateJunkJobEmployeeIsDriver({ commit }, { jobId, putJobEmployeeIsDriverDto }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .updateJunkJobEmployeeIsDriver(jobId, putJobEmployeeIsDriverDto)
      .then(data => {
        commit("SET_JOB_EMPLOYEES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Driver! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async updateJunkJobEmployeeTip({ commit, getters }, { jobEmployeeId, putJunkJobEmployeeTipDto }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .updateJunkJobEmployeeTip(getters.getCurrentJob?.id, jobEmployeeId, putJunkJobEmployeeTipDto)
      .then(data => {
        commit("SET_JOB_EMPLOYEES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Tip! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async fetchTipConfigurationByFranchiseId({ commit }, franchiseId) {
    commit("SET_IS_LOADING_TIP_CONFIGURATION", true)
    return await junkApi
      .fetchFranchiseTipConfigurationByFranchiseId(franchiseId)
      .then(data => {
        commit("SET_TIP_CONFIGURATION", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`Error Fetching Tip Configuration! ${errorMessage}`, "Retry", () => this.dispatch("job/fetchTipConfigurationByFranchiseId", franchiseId)),
          { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TIP_CONFIGURATION", false)
      })
  },
  async updateEmployeeDistributedTips({ commit }, { jobId, putEmployeeTipsDto }) {
    commit("SET_IS_LOADING_EMPLOYEE_TIPS", true)
    return await junkApi
      .addEmployeeDistributedTipsByJobId(jobId, putEmployeeTipsDto)
      .then(data => {
        commit("SET_CURRENT_JOB", data) // this here
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Employee Tips! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(errorMessage)
      })
      .finally(() => {
        commit("SET_IS_LOADING_EMPLOYEE_TIPS", false)
      })
  },
  async deleteJunkJobEmployee({ commit }, { jobId, jobEmployeeId }) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .deleteJunkJobEmployee(jobId, jobEmployeeId)
      .then(data => {
        commit("SET_JOB_EMPLOYEES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Deleting Employee! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async fetchJunkJobEmployees({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .fetchJunkJobEmployees(jobId)
      .then(data => {
        commit("SET_JOB_EMPLOYEES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Employees! ${error}`, "Retry", () => this.dispatch("Job/fetchJunkJobEmployees", jobId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false))
  },
  async updateGlobalTimes({ commit, dispatch }, updateTrucksAndEmployeeGlobalTimesDto) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .updateGlobalTimes(updateTrucksAndEmployeeGlobalTimesDto)
      .then(jobTrucks => {
        dispatch("setTrucksAndEmployeesGlobalTimeValues", undefined)
        commit("SET_JOB_TRUCKS", jobTrucks)
        return Promise.resolve(jobTrucks)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Global Times! ${errorMessage}`), { root: true })
        Sentry.captureException(errorMessage)
        return Promise.reject(errorMessage)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false)
      })
  },
  async fetchJunkJobTrucks({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", true)
    return await junkApi
      .fetchJunkJobTrucks(jobId)
      .then(data => {
        commit("SET_JOB_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Trucks! ${error}`, "Retry", () => this.dispatch("Job/fetchJunkJobTrucks", jobId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", false))
  },
  async setTrucksAndEmployeesGlobalTimeValues({ commit }, value) {
    commit("SET_TRUCKS_AND_EMPLOYEES_GLOBAL_START_TIME", value)
    commit("SET_TRUCKS_AND_EMPLOYEES_GLOBAL_END_TIME", value)
    commit("SET_TRUCKS_AND_EMPLOYEES_GLOBAL_DOWN_TIME", value)
  },
  async setAllJobComponentsLoadingState({ commit }, isLoading) {
    commit("SET_IS_LOADING_FRANCHISE_CAPACITIES", isLoading)
    commit("SET_IS_LOADING_JOB", isLoading)
    commit("SET_IS_LOADING_CUSTOMER_CONTACTS_WITH_ADDRESSES", isLoading)
    commit("SET_IS_LOADING_FRANCHISE_AND_SATELLITE_OFFICES", isLoading)
    commit("SET_IS_LOADING_JOB_ADDRESSES", isLoading)
    commit("SET_IS_LOADING_JOB_DETAILS", isLoading)
    commit("SET_IS_LOADING_JOB_MODIFIERS", isLoading)
    commit("SET_IS_LOADING_JOB_NOTES", isLoading)
    commit("SET_IS_LOADING_JOB_TRUCKS_AND_EMPLOYEES", isLoading)
    commit("SET_IS_LOADING_JOB_LEAD_SOURCE", isLoading)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
