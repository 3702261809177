<template>
  <v-container fluid class="pa-0 pa-xl-4 pa-lg-3 pa-md-2 pa-sm-1 mb-16">
    <mgt-get
      v-if="!isActionsDisabled"
      :resource="getUsersInOperatingUnitQuery"
      scopes="User.Read.All,Group.Read.All"
      version="v1.0"
      cache-enabled="true"
      @dataChange="onUsersDataChanged($event)"
    ></mgt-get>
    <mgt-get
      v-if="!isActionsDisabled"
      :resource="getJunkRolesQuery"
      scopes="User.Read.All,Group.Read.All"
      version="v1.0"
      cache-enabled="true"
      @dataChange="onRolesDataChanged($event)"
    />
    <v-card ref="settings-tab-franchise-specific-group-card" class="mb-4 pa-4 elevation-1" shaped color="primary">
      <v-row>
        <v-col cols="auto" class="col-xl-5 col-lg-5 col-xl-5 mt-0 justify-start flex-fill">
          <settings-franchise-selector-card
            v-if="getIsSelectedOperatingUnitIdSet"
            ref="settings-tab-franchise-selector-card"
            @selectedFranchiseUpdated="resetFranchiseSpecificEditableItems"
          />
          <settings-franchise-slot-summary-card ref="settings-tab-franchise-slot-summary-card" class="mt-4 elevation-4" />
        </v-col>
        <v-col cols="auto" class="mt-0 col-xl-7 col-lg-7 col-xl-7 justify-end flex-fill fill-height align-content-center align-center justify-center">
          <settings-truck-utilization-rates-card
            ref="settings-tab-truck-utilization-rates-card"
            @selectFranchiseSelector="focusOnSelectFranchiseSelector"
            :is-actions-disabled="isActionsDisabled"
          />
          <settings-franchise-cutoff-distance-card ref="settings-tab-franchise-cutoff-distance-card" class="mt-4" :is-actions-disabled="isActionsDisabled" />
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col>
          <settings-franchise-business-hours-card
            ref="settings-tab-franchise-business-hours-card"
            :is-actions-disabled="isActionsDisabled"
            @selectFranchiseSelector="focusOnSelectFranchiseSelector"
          />
          <settings-franchise-special-hours-card
            ref="settings-tab-franchise-special-hours-card"
            @selectFranchiseSelector="focusOnSelectFranchiseSelector"
            class="mt-4"
            :is-actions-disabled="isActionsDisabled"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <settings-franchise-tip-options-card
            ref="settings-franchise-tip-options-card"
            @selectFranchiseSelector="focusOnSelectFranchiseSelector"
            :tipConfig="getTipConfiguration"
            @update:tipConfig="updateTipConfiguration"
            :is-actions-disabled="isActionsDisabled"
          />
        </v-col>
      </v-row>
<!--      <v-row class="my-0 py-0">-->
<!--        <v-col class="my-0 py-0">-->
<!--          <settings-franchise-elected-discounts-for-website-card @selectFranchiseSelector="focusOnSelectFranchiseSelector" :is-actions-disabled="isActionsDisabled">></settings-franchise-elected-discounts-for-website-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </v-card>
    <v-row>
      <v-col>
        <settings-franchise-allocation-bucket-card
          ref="settings-tab-franchise-allocation-bucket-card"
          v-if="getIsSelectedOperatingUnitIdSet"
          :is-actions-disabled="isActionsDisabled"
        />
      </v-col>
    </v-row>
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <settings-national-discounts-card ref="settings-national-discounts-card" :is-actions-disabled="isActionsDisabled"></settings-national-discounts-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col>
        <settings-operating-unit-discounts-card ref="settings-tab-operating-unit-discounts-card" :is-actions-disabled="isActionsDisabled" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <settings-franchise-taxes-card ref="settings-tab-franchise-taxes-card" v-if="getIsSelectedOperatingUnitIdSet" :is-actions-disabled="isActionsDisabled" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <settings-trucks-card
          ref="settings-tab-trucks-card"
          v-if="getIsSelectedOperatingUnitIdSet"
          :is-actions-disabled="isActionsDisabled"
          @save-weekly-truck-allocation="onSaveWeeklyTruckAllocation($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <settings-truck-unavailability-card v-if="getIsSelectedOperatingUnitIdSet" :is-actions-disabled="isActionsDisabled" ref="settings-tab-truck-unavailability-card" />
      </v-col>
    </v-row>
    <base-fab ref="settings-tab-base-fab" v-if="!isActionsDisabled" :is-visible="!isActionsDisabled" :fab-options="fabOptions" />
    <settings-create-truck-dialog
      v-if="isCreateTruckDialogVisible"
      ref="settings-tab-create-truck-dialog"
      :is-dialog-visible="isCreateTruckDialogVisible"
      @closeDialog="setCreateTruckDialogVisibility(false)"
    />
    <settings-create-truck-unavailability-dialog
      v-if="isCreateTruckUnavailabilityDialogVisible"
      ref="settings-tab-create-truck-unavailability-dialog"
      :is-dialog-visible="isCreateTruckUnavailabilityDialogVisible"
      @closeDialog="setCreateTruckUnavailabilityDialogVisibility(false)"
    />
    <settings-create-tax-dialog
      v-if="isCreateTaxDialogVisible"
      ref="settings-tab-create-tax-dialog"
      :is-dialog-visible="isCreateTaxDialogVisible"
      @closeDialog="setCreateTaxDialogVisibility(false)"
    />
    <settings-create-franchise-special-hours-dialog
      v-if="isCreateFranchiseSpecialHoursDialogVisible"
      ref="settings-tab-create-franchise-special-hours-dialog"
      :is-dialog-visible="isCreateFranchiseSpecialHoursDialogVisible"
      @closeDialog="setCreateFranchiseSpecialHoursDialogVisibility(false)"
    />
    <settings-create-operating-unit-allocation-group-dialog
      v-if="isCreateOperatingUnitAllocationGroupDialogVisible"
      ref="settings-tab-create-operating-unit-allocation-group-dialog"
      :is-dialog-visible="isCreateOperatingUnitAllocationGroupDialogVisible"
      @closeDialog="setCreateOperatingUnitAllocationGroupDialogVisibility(false)"
    />
    <settings-create-operating-unit-discount-dialog
      ref="settings-tab-create-operating-unit-discount-dialog"
      v-if="isCreateDiscountDialogVisible"
      :is-dialog-visible="isCreateDiscountDialogVisible"
      @close-dialog="setIsCreateDiscountDialogVisible(false)"
    />
  </v-container>
</template>

<script>
import SettingsFranchiseSelectorCard from "@/components/SettingsFranchiseSelectorCard"
import SettingsFranchiseSlotSummaryCard from "@/components/SettingsFranchiseSlotSummaryCard"
import SettingsTruckUtilizationRatesCard from "@/components/SettingsTruckUtilizationRatesCard"
import SettingsFranchiseCutoffDistanceCard from "@/components/SettingsFranchiseCutoffDistanceCard"
import SettingsFranchiseBusinessHoursCard from "@/components/SettingsFranchiseBusinessHoursCard"
import SettingsFranchiseSpecialHoursCard from "@/components/SettingsFranchiseSpecialHoursCard"
import SettingsFranchiseTipOptionsCard from "@/components/SettingsFranchiseTipOptionsCard"
import SettingsFranchiseAllocationBucketCard from "@/components/SettingsFranchiseAllocationBucketCard"
import SettingsOperatingUnitDiscountsCard from "@/components/SettingsOperatingUnitDiscountsCard"
import SettingsFranchiseTaxesCard from "@/components/SettingsFranchiseTaxesCard"
import SettingsTrucksCard from "@/components/SettingsTrucksCard"
import SettingsTruckUnavailabilityCard from "@/components/SettingsTruckUnavailabilityCard"
import BaseFab from "@/components/BaseFab"
import SettingsCreateTruckDialog from "@/components/SettingsCreateTruckDialog"
import SettingsCreateTruckUnavailabilityDialog from "@/components/SettingsCreateTruckUnavailabilityDialog"
import SettingsCreateOperatingUnitDiscountDialog from "@/components/SettingsCreateOperatingUnitDiscountDialog"
import SettingsCreateTaxDialog from "@/components/SettingsCreateTaxDialog"
import SettingsCreateFranchiseSpecialHoursDialog from "@/components/SettingsCreateFranchiseSpecialHoursDialog"
import SettingsCreateOperatingUnitAllocationGroupDialog from "@/components/SettingsCreateOperatingUnitAllocationGroupDialog"
import { EnvironmentVariant } from "@/enums/Environments"
import { mapActions, mapGetters } from "vuex"
import { putJunkTruckWeeklyAllocationDto } from "@/api/dtos/JunkDtos"
import { warningSnackbar } from "@/utils/SnackbarBuilder"
import { tomorrowAsDate } from "@/utils/DateTimeFormatters"
// import SettingsFranchiseElectedDiscountsForWebsiteCard from "@/components/SettingsFranchiseElectedDiscountsForWebsiteCard.vue"
// import SettingsNationalDiscountsCard from "@/components/SettingsNationalDiscountsCard.vue"

// todo: Re-add SettingsNationalDiscountsCard and SettingsFranchiseElectedDiscountsForWebsiteCard when marketing is good to go
export default {
  name: "SettingsTab",
  components: {
    // SettingsNationalDiscountsCard,
    // SettingsFranchiseElectedDiscountsForWebsiteCard,
    SettingsFranchiseSelectorCard,
    SettingsFranchiseSlotSummaryCard,
    SettingsTruckUtilizationRatesCard,
    SettingsFranchiseCutoffDistanceCard,
    SettingsFranchiseBusinessHoursCard,
    SettingsFranchiseSpecialHoursCard,
    SettingsFranchiseTipOptionsCard,
    SettingsFranchiseAllocationBucketCard,
    SettingsOperatingUnitDiscountsCard,
    SettingsFranchiseTaxesCard,
    SettingsTrucksCard,
    SettingsTruckUnavailabilityCard,
    BaseFab,
    SettingsCreateTruckDialog,
    SettingsCreateTruckUnavailabilityDialog,
    SettingsCreateOperatingUnitDiscountDialog,
    SettingsCreateTaxDialog,
    SettingsCreateFranchiseSpecialHoursDialog,
    SettingsCreateOperatingUnitAllocationGroupDialog
  },
  data() {
    return {
      isCreateTruckDialogVisible: false,
      isCreateTruckUnavailabilityDialogVisible: false,
      isCreateFranchiseSpecialHoursDialogVisible: false,
      isCreateFranchiseAddOnsDialogVisible: false,
      isCreateFranchiseDiscountDialogVisible: false,
      isCreateTaxDialogVisible: false,
      isCreateOperatingUnitAllocationGroupDialogVisible: false,
      isCreateDiscountDialogVisible: false,
      minimumPickerDate: tomorrowAsDate(),
    }
  },
  computed: {
    ...mapGetters(["getSelectedOperatingUnitId", "getAppEnvironment", "getAzureTypeExtKey", "getAzureOperatingUnitEnvironmentExtKey", "getIsSelectedOperatingUnitIdSet"]),
    ...mapGetters("Settings", ["getSelectedFranchise", "getOperatingUnitAllocationGroups", "getIsLoadingFranchisesInOperatingUnit", "getTipConfiguration"]),
    fabOptions() {
      return [
        {
          icon: "mdi-truck-plus-outline",
          onClickAction: () => this.setCreateTruckDialogVisibility(true),
          isFabDisabled: this.isActionsDisabled,
          text: "Create Truck"
        },
        {
          icon: "mdi-car-wrench",
          onClickAction: () => this.setCreateTruckUnavailabilityDialogVisibility(true),
          isFabDisabled: this.isActionsDisabled,
          text: "Add Truck Unavailability"
        },
        {
          icon: "mdi-tag-plus",
          onClickAction: () => this.setIsCreateDiscountDialogVisible(true),
          isFabDisabled: this.isActionsDisabled || this.getIsLoadingFranchisesInOperatingUnit,
          text: "Create Discount"
        },
        {
          icon: "mdi-cash-plus",
          onClickAction: () => this.setCreateTaxDialogVisibility(true),
          isFabDisabled: this.isActionsDisabled,
          text: "Create Tax"
        },
        {
          icon: "mdi-calendar-clock-outline",
          onClickAction: () => this.setCreateFranchiseSpecialHoursDialogVisibility(true),
          isFabDisabled: this.isActionsDisabled,
          text: "Create Special Hours"
        },
        {
          icon: "mdi-basket-plus",
          onClickAction: () => this.setCreateOperatingUnitAllocationGroupDialogVisibility(true),
          isFabDisabled: this.isCreateOperatingUnitAllocationGroupDialogFabDisabled,
          text: "Create Allocation Group"
        }
      ]
    },
    getUsersInOperatingUnitQuery() {
      return `/groups/${this.getSelectedOperatingUnitId}/members/microsoft.graph.user?$expand=memberOf`
    },
    getJunkRolesQuery() {
      if (this.getAppEnvironment !== EnvironmentVariant.PRODUCTION) {
        return `groups?$filter=${this.getAzureTypeExtKey}+eq+'role' and ${this.getAzureOperatingUnitEnvironmentExtKey} eq '${this.getAppEnvironment}' and (displayName eq 'CSR (${this.getAppEnvironment})' or displayName eq 'Franchise Owner (${this.getAppEnvironment})' or displayName eq 'Junk Manager (${this.getAppEnvironment})' or displayName eq 'Hauler (${this.getAppEnvironment})')&$count=true&$select=id,displayName,description`
      } else {
        return `groups?$filter=${this.getAzureTypeExtKey}+eq+'role' and ${this.getAzureOperatingUnitEnvironmentExtKey} eq '${this.getAppEnvironment}' and (displayName eq 'CSR' or displayName eq 'Franchise Owner' or displayName eq 'Junk Manager' or displayName eq 'Hauler')&$count=true&$select=id,displayName,description`
      }
    },
    isActionsDisabled() {
      return !this.$msal.getUserHasWriteSettingsPermission()
    },
    isCreateOperatingUnitAllocationGroupDialogFabDisabled() {
      return this.isActionsDisabled || (this.getOperatingUnitAllocationGroups?.length ?? 0) <= 1
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    ...mapActions("Settings", [
      "fetchVehicleFuelTypes",
      "resetFranchiseData",
      "fetchActiveFranchisesInOperatingUnit",
      "fetchJunkTrucksByOperatingUnitIds",
      "fetchUnavailableTrucksByOperatingUnitIds",
      "fetchFranchiseTaxes",
      "setUsersInOperatingUnits",
      "setAllRoles",
      "updateWeeklyTruckAllocation",
      "updateWeeklyTruckAllocation",
      "fetchOperatingUnitAllocationGroups",
      "fetchOperatingUnitDiscounts",
      "fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId",
      "updateTipConfiguration"
    ]),
    setIsCreateDiscountDialogVisible(isVisible) {
      this.isCreateDiscountDialogVisible = isVisible
    },
    async onSaveWeeklyTruckAllocation(data) {
      const dto = putJunkTruckWeeklyAllocationDto(data.junkTruckWeeklyAllocationId, data.amSlots, data.pmSlots, data.dispatchLocationId, this.$msal.getCurrentUserId())
      await this.updateWeeklyTruckAllocation(dto)
    },
    async setCreateFranchiseSpecialHoursDialogVisibility(isVisible) {
      if ((this.getSelectedFranchise === undefined || this.getSelectedFranchise === null) && isVisible) {
        await this.addSnackbar(warningSnackbar("Please select a franchise to create special hours.", 5000))
        await this.focusOnSelectFranchiseSelector()
        return
      }
      this.isCreateFranchiseSpecialHoursDialogVisible = isVisible
    },
    setCreateTruckDialogVisibility(isVisible) {
      this.isCreateTruckDialogVisible = isVisible
    },
    setCreateTruckUnavailabilityDialogVisibility(isVisible) {
      this.isCreateTruckUnavailabilityDialogVisible = isVisible
    },
    setCreateTaxDialogVisibility(isVisible) {
      this.isCreateTaxDialogVisible = isVisible
    },
    async focusOnSelectFranchiseSelector() {
      await this.$vuetify.goTo(this.$refs["settings-tab-franchise-selector-card"])
      this.$nextTick(() => {
        this.$refs["settings-tab-franchise-selector-card"].$refs["settings-franchise-selector-card-select"].isMenuActive = true
      })
    },
    resetFranchiseSpecificEditableItems() {
      this.$refs["settings-tab-truck-utilization-rates-card"].resetEditableItem()
    },
    onUsersDataChanged(event) {
      let users = event?.detail?.response?.value ?? []
      let usersInOperatingUnit = this.filterUsersByRoles(users)
      usersInOperatingUnit = this.mapUserRoles(usersInOperatingUnit)
      this.setUsersInOperatingUnits(usersInOperatingUnit)
    },
    filterUsersByRoles(users) {
      let roles = ["corporate admin", "sales support"]
      if (this.getAppEnvironment !== EnvironmentVariant.PRODUCTION) {
        roles = roles.map(role => `${role} (${this.getAppEnvironment})`)
      }
      return users.filter(item => !item.memberOf.some(group => roles.some(role => group?.displayName?.toLowerCase() === role)))
    },
    mapUserRoles(users) {
      return users.map(item => {
        return {
          ...item,
          roles: this.getUserRolesFormatted(item.memberOf)
        }
      })
    },
    getUserRolesFormatted(userRoles) {
      return userRoles
        .filter(role => role[this.getAzureTypeExtKey] === "role" && role[this.getAzureOperatingUnitEnvironmentExtKey] === this.getAppEnvironment)
        .map(u => u.displayName)
        ?.join(", ")
    },
    onRolesDataChanged(event) {
      this.setAllRoles(event?.detail?.response?.value ?? [])
    },
    setCreateOperatingUnitAllocationGroupDialogVisibility(isVisible) {
      this.isCreateOperatingUnitAllocationGroupDialogVisible = isVisible
    }
  },
  async created() {
    await this.fetchVehicleFuelTypes()

    this.$eventBus.$on("operating-unit-updated", operatingUnitMetadataObj => {
      this.resetFranchiseSpecificEditableItems()
      this.resetFranchiseData()
      this.fetchActiveFranchisesInOperatingUnit(operatingUnitMetadataObj.id)
      this.fetchJunkTrucksByOperatingUnitIds([operatingUnitMetadataObj.id])
      this.fetchUnavailableTrucksByOperatingUnitIds([operatingUnitMetadataObj.id])
      this.fetchFranchiseTaxes(operatingUnitMetadataObj.id)
      this.fetchOperatingUnitAllocationGroups(operatingUnitMetadataObj.id)
      this.fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId(operatingUnitMetadataObj.id)
      this.fetchOperatingUnitDiscounts(operatingUnitMetadataObj.id)
    })
  }
}
</script>
