<template>
  <v-card class="mt-4 primary-light-solid-border" elevation="0" ref="job-employees-card-root">
    <v-card-title ref="job-employees-card-title">Employees</v-card-title>
    <v-card-text class="pb-2">
      <v-data-table
        ref="job-employees-card-employees-table"
        style="min-height: 120px"
        :headers="employeeHeaders"
        :items="getEmployeesOnJob"
        :loading="getIsLoadingTrucksAndEmployees"
        loader-height="0"
        item-key="jobEmployeeId"
        loading-text="🔎 Fetching Employees 🔍"
        mobile-breakpoint="900"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:no-data>
          <v-col class="ma-0 pa-0 align-self-center d-flex">
            <app-not-found title="No employees!" content="This job does not currently have any employees.">
              <template v-slot:actions>
                <div></div>
              </template>
            </app-not-found>
          </v-col>
        </template>
        <template v-slot:item.name="{ item }">
          <span class="text-md-body-1" ref="job-trucks-and-employees-employee-name">{{ formatEmployeeName(item.firstName, item.lastName) }}</span>
        </template>
        <template v-slot:item.isDriver="{ item }">
          <v-checkbox :input-value="item.isDriver" @change="updateJobEmployeeIsDriver(item.jobId, item.jobEmployeeId, $event)" color="primary" :disabled="isActionsDisabled" />
        </template>
        <template v-slot:item.tip="{ item }">
          <v-text-field
            class="pt-4 mb-n4 flex-fill"
            ref="job-trucks-and-employees-total-down-time-text"
            style="min-width: 140px"
            :value="formatDecimal(item.tip)"
            :rules="tipValidationRules"
            :key="tipTextFieldKey"
            @change="updateEmployeeTipAmount(item.jobEmployeeId, $event)"
            :disabled="isActionsDisabled"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
            min="0"
            max="999999.99"
            validate-on-blur
            single-line
            outlined
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="justify-center">
            <v-btn ref="job-trucks-and-employees-card-delete-employee-btn" @click="deleteEmployee(item)" :disabled="isActionsDisabled" icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-if="isEmployeeTotalsItemVisible" v-slot:body.append>
          <tr v-if="$vuetify.breakpoint.mdAndUp">
            <td colspan="2"></td>
            <td class="text-md-body-1 justify-center" colspan="1">
              <app-number-formatter :amount="getCurrentJob.totalTip" currencySign="accounting" numberFormatStyle="currency"></app-number-formatter>
            </td>
            <td colspan="1"></td>
          </tr>
          <tr v-else>
            <td class="text-md-body-1 py-4">
              <v-row>
                <v-col cols="auto" class="flex-column float-start">
                  <div class="float-start flex-fill"><strong>Totals Hours: </strong></div>
                </v-col>
                <v-col cols="auto" class="flex-column">
                  <app-decimal-formatter :amount="totalEmployeeHours"></app-decimal-formatter>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto" class="flex-column">
                  <div class="float-start flex-fill"><strong>Totals Tips: </strong></div>
                </v-col>
                <v-col cols="auto" class="flex-column">
                  <app-number-formatter :amount="getCurrentJob.totalTip" currencySign="accounting" numberFormatStyle="currency"></app-number-formatter>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import AppDecimalFormatter from "@/components/AppDecimalFormatter"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"
import { putJobEmployeeIsDriverDto, putJunkJobEmployeeTipDto } from "@/api/dtos/JunkDtos"

export default {
  name: "JobEmployeesCard",
  components: { AppNumberFormatter, AppDecimalFormatter, AppNotFound },
  data() {
    return {
      tipTextFieldKey: 0,
      employeeHeaders: [
        { text: "Employee Name", align: "start", value: "name" },
        { text: "Driver", align: "start", value: "isDriver" },
        { text: "Tip", align: "center", value: "tip" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ],
      tipValidationRules: [
        value => !!value || "Tip is required.",
        value => value >= 0 || "Tip must be positive or zero.",
        value => value <= 999999.99 || "Tip must be less than or equal to $999,999.99."
      ],
      totalDownTimeValidationRules: [value => value >= 0 || "Time must be positive or zero.", value => value <= 24 || "Time must be less than 24 hours."]
    }
  },
  methods: {
    ...mapActions("Job", ["deleteJunkJobEmployee", "updateJunkJobEmployeeIsDriver", "updateJunkJobEmployeeTip", "fetchJunkJobByJobId"]),
    allowedMinutes: m => m % 15 === 0,
    formatTime(value) {
      return new dayjs(value).format("HH:mm")
    },
    formatEmployeeName(firstName, lastName) {
      return `${lastName}, ${firstName}`
    },
    formatDecimal(value) {
      return formatDecimalValue(value)
    },
    doesEmployeeHaveBreakTimeRecord(jobEmployee) {
      return jobEmployee.jobEmployeeBreakTimes?.length > 0
    },
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    },
    async updateEmployeeTipAmount(jobEmployeeId, tipAmount) {
      const dto = putJunkJobEmployeeTipDto(tipAmount, this.$msal.getCurrentUserId())
      await this.updateJunkJobEmployeeTip({ jobEmployeeId: jobEmployeeId, putJunkJobEmployeeTipDto: dto })
        .then(() => {
          this.fetchJunkJobByJobId(this.getCurrentJob.id)
        })
        .catch(() => {
          this.updateTipTextFieldKey()
        })
    },
    async deleteEmployee(item) {
      await this.deleteJunkJobEmployee({ jobId: item.jobId, jobEmployeeId: item.jobEmployeeId }).then(() => {
        this.fetchJunkJobByJobId(this.getCurrentJob.id)
      })
    },
    async updateJobEmployeeIsDriver(jobId, jobEmployeeId, event) {
      const dto = putJobEmployeeIsDriverDto(jobEmployeeId, event, this.$msal.getCurrentUserId())
      await this.updateJunkJobEmployeeIsDriver({ jobId: jobId, putJobEmployeeIsDriverDto: dto })
    },
    updateTipTextFieldKey() {
      this.tipTextFieldKey === 1 ? this.tipTextFieldKey = 0 : this.tipTextFieldKey = 1
    }
  },
  computed: {
    ...mapGetters("Job", ["getCurrentJob", "getCurrentJobScheduledDateOrToday", "getEmployeesOnJob", "getIsLoadingTrucksAndEmployees", "getIsJobFieldsDisabled"]),
    isEmployeeTotalsItemVisible() {
      return this.getEmployeesOnJob?.length > 1
    },
    totalEmployeeHours() {
      return this.getEmployeesOnJob?.reduce((total, employee) => {
        return total + (employee?.totalHoursMinusDownTimes ?? 0.0)
      }, 0)
    },
    isActionsDisabled() {
      return this.getIsLoadingTrucksAndEmployees || this.getIsJobFieldsDisabled
    }
  }
}
</script>
