<template>
  <base-dialog ref="job-receipt-resend-dialog-root" title="Resend Statement" :is-dialog-visible="isDialogVisible" dialog-image="mdi-email-fast">
    <template class="flex-fill" v-slot:content>
      <job-receipt-recipients-card ref="job-receipt-resend-dialog-job-receipt-recipients-card" :showIsPrimaryContactEmailNotProvidedDisclaimer="true" />
    </template>
    <template v-slot:actions>
      <v-btn ref="job-receipt-resend-dialog-cancel-receipt-btn" class="justify-end pa-4" color="primary" text rounded ripple @click="closeDialog">Cancel</v-btn>
      <v-btn ref="job-receipt-resend-dialog-send-receipt-btn" :disabled="isSendDisabled" class="justify-end pa-4" color="primary" text rounded ripple @click="handleSendReceipt">Send</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import JobReceiptRecipientsCard from "@/components/JobReceiptRecipientsCard.vue"
import { mapGetters } from "vuex"

export default {
  name: "JobReceiptResendDialog",
  components: { JobReceiptRecipientsCard, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSendReceipt() {
      this.$emit("send")
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapGetters("Job", ["getSelectedEmailReceiptRecipients"]),
    isSendDisabled() {
      return this.getSelectedEmailReceiptRecipients.length <= 0
    }
  }
}
</script>
