<template>
  <v-layout>
    <v-card class="flex d-flex flex-column" ref="job-franchise-selector-card" :loading="getIsLoadingFranchiseAndSatelliteOffices" elevation="4">
      <v-card-title ref="job-franchise-selector-card-title" class="headline">Franchise</v-card-title>
      <v-card-text>
        <div :class="{ cursor_disabled: this.isFranchiseSelectionDisabled }">
          <v-select
            class="mb-4 cursor_pointer"
            ref="job-franchise-selector"
            color="primary"
            label="Selected Franchise"
            item-text="franchiseNameAndNumber"
            :key="franchiseSelectionKey"
            :value="getSelectedFranchise"
            @change="promptIsOverAllocationDialogOrUpdateSelectedFranchise"
            :items="getUserActiveFranchisesFiltered"
            :disabled="isFranchiseSelectionDisabled"
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            outlined
            hide-details
          />
        </div>
        <div :class="{ cursor_disabled: this.isFranchiseSatelliteOfficeSelectionDisabled }">
          <v-select
            class="cursor_pointer"
            ref="job-franchise-satellite-office-selector"
            color="primary"
            label="Selected Franchise Satellite Office"
            item-text="name"
            clear-icon="mdi-close-circle"
            clearable
            :key="satelliteOfficeSelectionKey"
            :value="getSelectedSatelliteOffice"
            @change="updateSelectedFranchiseSatelliteOffice"
            :items="getFranchiseSatelliteOfficesInFranchise"
            :disabled="isFranchiseSatelliteOfficeSelectionDisabled"
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            outlined
            hide-details
          />
        </div>
      </v-card-text>
    </v-card>
    <base-dialog v-if="updateSelectedFranchiseConfirmationObject.isVisible" ref="app-dialog-root" title="Switch Selected Franchise?" :is-dialog-visible="updateSelectedFranchiseConfirmationObject.isVisible" dialog-image="mdi-alert-outline">
      <template class="flex-fill" v-slot:content>
        <v-row class="mb-2" v-if="updateSelectedFranchiseConfirmationObject.isTrucksAndEmployeesWarningVisible">
          <div class="headline">Trucks and Employees</div>
          <span class="text-md-body-1 ms-4">Moving the job to this franchise will <strong>remove</strong> any assigned <strong>trucks and employees</strong>.</span>
        </v-row>
        <v-row v-if="updateSelectedFranchiseConfirmationObject.isOverSlotAllocationWarningVisible">
          <div class="headline">Over Slot Allocation</div>
          <span class="text-md-body-1 ms-4">Scheduling this job will put you over the allocation amount for this job's <strong>Preferred Time of Day: <em>{{ getCurrentJob.preferredTimeSlotName }}</em></strong> and <strong>Scheduled Date: <em>{{ getCurrentJobScheduledDateOrToday }}</em></strong>.</span>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="app-confirmation-dialog-cancel-btn" color="primaryText" text rounded ripple @click="cancelUpdateSelectedFranchise">Cancel</v-btn>
        <v-btn class="pa-4" ref="app-confirmation-dialog-confirm-btn" color="primary" text rounded ripple @click="confirmUpdateSelectedFranchise" :disabled="getIsLoadingFranchiseAndSatelliteOffices" :loading="getIsLoadingFranchiseAndSatelliteOffices">Confirm</v-btn>
      </template>
    </base-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { fetchScheduleSlotDto, putJunkJobDto, putJunkJobSatelliteOfficeDto } from "@/api/dtos/JunkDtos"
import { getMinimumDateOrFirstOfMonth, getLastDayOfMonthByYearAndMonth, getYearAndMonthFromDateTime } from "@/utils/DateTimeFormatters"
import { consoleLog } from "@/utils/Logging"
import BaseDialog from "@/components/BaseDialog"

export default {
  name: "JobFranchiseSelectorCard",
  components: { BaseDialog },
  data() {
    return {
      franchiseSelectionKey: 0,
      satelliteOfficeSelectionKey: 0,
      isConfirmationDialogVisible: false,
      updateSelectedFranchiseConfirmationObject: {
        isVisible: false,
        isOverSlotAllocationWarningVisible: false,
        isTrucksAndEmployeesWarningVisible: false,
        newFranchiseId: null
      }
    }
  },
  methods: {
    ...mapActions("Job", [
      "fetchActiveFranchiseCapacitiesByFranchiseIdAndDate",
      "updateJunkJobSelectedFranchiseSatelliteOffice",
      "updateSelectedFranchiseAndCapacityByFranchiseIdAndCapacityId",
      "fetchScheduleSlotAllocations",
      "fetchJunkJobAddresses",
      "fetchActiveJunkTrucksInOperatingUnitByDate",
      "fetchJunkJobTrucks",
      "fetchActiveFranchiseTaxes",
      "fetchJunkJobEmployees",
      // "fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate",
      "fetchApplicableDiscounts",
      "fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId"
    ]),
    resetSelectedFranchiseConfirmationObject() {
      this.updateSelectedFranchiseConfirmationObject = Object.assign({}, {
          isVisible: false,
          isOverSlotAllocationWarningVisible: false,
          isTrucksAndEmployeesWarningVisible: false,
          newFranchiseId: null
        }
      )
    },
    async promptIsOverAllocationDialogOrUpdateSelectedFranchise(franchise) {
      consoleLog("franchiseObj = ", franchise)
      let updateSelectedFranchiseConfirmationObject = {
        isVisible: false,
        isOverSlotAllocationWarningVisible: false,
        isTrucksAndEmployeesWarningVisible: false,
        newFranchiseId: franchise.franchiseId
      }
      const isOverSlotAllocation = await this.fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId({ franchiseId: franchise.franchiseId, date: this.getCurrentJobScheduledDateOrToday, preferredTimeSlotId: this.getCurrentJob.preferredTimeSlotId })
      updateSelectedFranchiseConfirmationObject.isVisible = isOverSlotAllocation
      updateSelectedFranchiseConfirmationObject.isOverSlotAllocationWarningVisible = isOverSlotAllocation

      if (this.getCurrentJob.operatingUnitId !== franchise.operatingUnitId && (this.getTrucksOnJob.length > 0 || this.getEmployeesOnJob.length > 0)) {
        updateSelectedFranchiseConfirmationObject.isVisible = true
        updateSelectedFranchiseConfirmationObject.isTrucksAndEmployeesWarningVisible = true
      }

      if (updateSelectedFranchiseConfirmationObject.isOverSlotAllocationWarningVisible || updateSelectedFranchiseConfirmationObject.isTrucksAndEmployeesWarningVisible) {
        this.updateSelectedFranchiseConfirmationObject = Object.assign({}, updateSelectedFranchiseConfirmationObject)
      } else {
        await this.updateSelectedFranchiseAndCapacityId(franchise.franchiseId)
      }
    },
    async confirmUpdateSelectedFranchise() {
      await this.updateSelectedFranchiseAndCapacityId(this.updateSelectedFranchiseConfirmationObject.newFranchiseId)
        .then(() => {
          this.fetchJunkJobTrucks(this.getCurrentJob.id)
        })
        .catch(() => {
          this.cancelUpdateSelectedFranchise()
        })
    },
    cancelUpdateSelectedFranchise() {
      this.updateFranchiseSelectionKey()
      this.resetSelectedFranchiseConfirmationObject()
    },
    async updateSelectedFranchiseAndCapacityId(franchiseId) {
      const dto = putJunkJobDto(this.getCurrentJob.id, this.getCurrentJobScheduledDateOrToday, this.getCurrentJob.operatingUnitId, franchiseId, this.getCurrentJob.capacityId, this.$msal.getCurrentUserId())
      await this.updateSelectedFranchiseAndCapacityByFranchiseIdAndCapacityId(dto).then(() => {
        this.resetSelectedFranchiseConfirmationObject()
        this.fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({ franchiseId: franchiseId, date: this.getCurrentJobScheduledDateOrToday })
        this.fetchJunkJobAddresses(this.getCurrentJob.id)

        // const currentScheduleDateYearAndMonth = formatAsDateOnly(this.getCurrentJob.scheduledDate).substring(0, 7)
        const fromDate = getMinimumDateOrFirstOfMonth(getYearAndMonthFromDateTime(this.getCurrentJobScheduledDateOrToday))
        const toDate = getLastDayOfMonthByYearAndMonth(getYearAndMonthFromDateTime(this.getCurrentJobScheduledDateOrToday))
        const scheduleSlotDto = fetchScheduleSlotDto(this.getCurrentJob.franchiseId, fromDate, toDate)
        this.fetchScheduleSlotAllocations(scheduleSlotDto)
        this.fetchActiveJunkTrucksInOperatingUnitByDate({ date: this.getCurrentJobScheduledDateOrToday, operatingUnitId: this.getCurrentJob.operatingUnitId })
        this.fetchActiveFranchiseTaxes({ franchiseId: franchiseId, date: this.getCurrentJobScheduledDateOrToday })
        this.fetchJunkJobEmployees(this.getCurrentJob.id)
        this.fetchApplicableDiscounts(this.getCurrentJob.id)
        // this.fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate({ date: this.getCurrentJobScheduledDateOrToday, operatingUnitId: this.getCurrentJob.operatingUnitId })
      })
    },
    async updateSelectedFranchiseSatelliteOffice(franchiseSatelliteOffice) {
      this.$refs["job-franchise-satellite-office-selector"].blur()
      const dto = putJunkJobSatelliteOfficeDto(this.getCurrentJob.id, franchiseSatelliteOffice?.id, this.$msal.getCurrentUserId()) // todo: Replace with current user's username when auth is wired up.
      await this.updateJunkJobSelectedFranchiseSatelliteOffice(dto)
        .then(() => {
          this.fetchJunkJobAddresses(this.getCurrentJob.id)
        })
        .catch(() => {
          this.updateSatelliteOfficeSelectionKey()
        })
    },
    updateFranchiseSelectionKey() {
      this.franchiseSelectionKey === 1 ? this.franchiseSelectionKey = 0 : this.franchiseSelectionKey = 1
    },
    updateSatelliteOfficeSelectionKey() {
      this.satelliteOfficeSelectionKey === 1 ? this.satelliteOfficeSelectionKey = 0 : this.satelliteOfficeSelectionKey = 1
    }
  },
  computed: {
    ...mapGetters(["getUserActiveFranchises"]),
    ...mapGetters("Job", [
      "getFranchisesInOperatingUnit",
      "getFranchiseSatelliteOfficesInFranchise",
      "getSelectedSatelliteOffice",
      "getCurrentJob",
      "getSelectedFranchise",
      "getIsLoadingFranchiseAndSatelliteOffices",
      "getFormattedServiceDateAsDayOfWeekMonthDayYear",
      "getIsJobFieldsDisabled",
      "getCurrentJobScheduledDateOrToday",
      "getTrucksOnJob",
      "getEmployeesOnJob"
    ]),
    isFranchiseSelectionDisabled() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || (this.getUserActiveFranchises?.length ?? 0) < 2 || this.getIsJobFieldsDisabled
    },
    isFranchiseSatelliteOfficeSelectionDisabled() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || (this.getFranchiseSatelliteOfficesInFranchise?.length ?? 0) < 1 || this.getIsJobFieldsDisabled
    },
    getUserActiveFranchisesFiltered() {
      // todo: filter this list to only show franchises that are active for the job's scheduled date
      // todo: Additionally, we should probably only show satellite office's that are active?
      // todo: Add sorting by franchiseNameAndNumber
      // todo: Restrict on backend
      consoleLog("getUserActiveFranchises = ", this.getUserActiveFranchises)
      return this.getUserActiveFranchises
    }
  }
}
</script>

<style scoped></style>
