<template>
  <base-dialog ref="settings-create-operating-unit-discount-dialog-root" title="Create Discount" max-dialog-width="900" :is-dialog-visible="isDialogVisible" dialog-image="mdi-tag-plus">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="settings-create-operating-unit-discount-dialog-form" v-model="isCreateDiscountFormValid">
        <v-layout row>
          <v-text-field
            ref="settings-create-operating-unit-discount-dialog-name-input"
            label="Discount Name *"
            hint="This will be displayed on the Junk Service Statement."
            outlined
            maxlength="50"
            counter="50"
            persistent-hint
            validate-on-blur
            v-model.trim="discountName"
            :rules="isRequiredRules"
            @focus="selectAllContent"
          />
        </v-layout>
        <v-layout row>
          <v-text-field
            ref="administrator-create-discount-dialog-promo-code-input"
            label="Promo Code *"
            v-model.trim="promoCode"
            hint="This should be a unique code that customers will enter to receive the discount."
            outlined
            minlength="5"
            maxlength="25"
            counter="25"
            :persistent-hint="true"
            :rules="promoCodeRules"
            validate-on-blur
            @focus="selectAllContent"
            @input="toUpperCase"
          />
        </v-layout>
        <v-layout row class="justify-center">
          <v-col cols="3" class="ma-0 pa-0 col-md-2 col-lg-2">
            <v-chip-group ref="settings-create-operating-unit-discount-dialog-chip-group" class="mt-1" color="primary" v-model="isPercentage" @change="setCursorFocusOnAmountField" mandatory>
              <v-chip ref="settings-create-operating-unit-discount-dialog-chip" label outlined v-for="item in discountUnitChips" :value="item.value" :key="item.value" :aria-label="item.label">
                <v-icon ref="settings-create-operating-unit-discount-dialog-chip-icon">{{ item.icon }}</v-icon>
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="9" class="ma-0 my-0 col-md-10 col-lg-4 pa-0">
            <v-text-field
              class="text-h5"
              ref="settings-create-operating-unit-discount-dialog-amount-input"
              v-model="discountAmount"
              :label="getDiscountTypeLabel"
              :hint="getDiscountTypeHint"
              :rules="discountAmountValidationRules"
              :hide-details="isAmountHideDetails"
              @focus="selectAllContent"
              @focusin="isAmountHideDetails = false"
              @focusout="isAmountHideDetails = true"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6" class="col-md-6 col-lg-3 mt-lg-n3 mt-md-0 ps-0 ps-lg-4 d-flex">
            <app-date-picker-field-selector
              ref="settings-create-operating-unit-discount-dialog-begin-date-input"
              text-field-format="MM/DD/YYYY"
              text-field-label="Begin Date *"
              :date-picker-value="beginDate"
              :minimum-date-value="minimumDate"
              :maximum-date-value="endDate"
              @updatedPickerValue="setBeginDateTime($event)"
            />
          </v-col>
          <v-col cols="6" class="col-md-6 col-lg-3 mt-lg-n3 mt-md-0 pe-0 d-flex">
            <app-date-picker-field-selector
              ref="settings-create-operating-unit-discount-dialog-end-date-input"
              text-field-format="MM/DD/YYYY"
              text-field-label="End Date"
              is-clear-enabled
              :date-picker-value="endDate"
              :minimum-date-value="beginDate"
              @updatedPickerValue="setEndDateTime($event)"
            />
          </v-col>
        </v-layout>
        <v-layout row>
          <v-divider class="mb-4 mt-1" :style="{ background: 'var(--v-primary-base)' }" />
        </v-layout>
        <v-layout row>
          <v-data-table
              ref="settings-create-operating-unit-discount-dialog-data-table"
              class="flex-fill"
              item-class="text-md-body-1"
              item-key="franchiseId"
              sort-by="franchiseNumberAndName"
              hide-default-footer
              disable-pagination
              fixed-header
              height="32vh"
              :headers="headers"
              :items="selectedFranchises"
          >
            <template v-slot:item.isEnrolled="{ item }">
              <v-simple-checkbox ref="settings-create-operating-unit-discount-dialog-enroll-checkbox" color="primary" :value="item.isEnrolled" @input="updateSelectedFranchises($event, item)" />
            </template>
            <template v-slot:item.beginDate="{ item }">
              <v-col class="ma-0 pa-0">
                <app-date-picker-field-selector
                  v-if="item.isEnrolled"
                  class="flex-fill"
                  ref="settings-create-operating-unit-discount-dialog-begin-date-input"
                  text-field-format="MM/DD/YYYY"
                  is-text-field-dense
                  :date-picker-value="item.beginDate"
                  :minimum-date-value="beginDate"
                  :maximum-date-value="item.endDate"
                  @updatedPickerValue="setItemBeginDateTime(item.franchiseId, $event)"
                />
              </v-col>
            </template>
            <template v-slot:item.endDate="{ item }">
              <v-col class="pe-12 pe-lg-0 pe-xl-0">
                <app-date-picker-field-selector
                  v-if="item.isEnrolled"
                  ref="settings-create-operating-unit-discount-dialog-end-date-input"
                  class="flex-fill"
                  text-field-format="MM/DD/YYYY"
                  is-clear-enabled
                  is-text-field-dense
                  :date-picker-value="item.endDate"
                  :minimum-date-value="item.beginDate"
                  :maximum-date-value="endDate"
                  @updatedPickerValue="setItemEndDateTime(item.franchiseId, $event)"
                />
              </v-col>
            </template>
          </v-data-table>
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-create-operating-unit-discount-dialog-cancel-button" color="primaryText" text rounded ripple @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn
        ref="settings-create-operating-unit-discount-dialog-create-button"
        class="pa-4"
        color="primary"
        text
        rounded
        ripple
        :loading="getIsLoadingDiscounts"
        :disabled="isCreateDisabled"
        @click="createDiscount"
      >
        Create
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { mapActions, mapGetters } from "vuex"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { createOperatingUnitDiscountDto, createOperatingUnitFranchiseElectedDiscountDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsCreateOperatingUnitDiscountDialog",
  components: { BaseDialog, AppDatePickerFieldSelector },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      discountName: "",
      isPercentage: false,
      discountAmount: 0.0,
      discountPercent: 0.0,
      selectedFranchises: [],
      beginDate: todayAsDate(),
      endDate: null,
      minimumDate: todayAsDate(),
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      discountUnitChips: [
        { label: "Dollar", icon: "mdi-currency-usd", value: false },
        { label: "Percentage", icon: "mdi-percent-outline", value: true }
      ],
      promoCode: "",
      promoCodeRules: [
        (value) => value.length >= 5 || "Must be at least 5 characters",
        (value) => value.length <= 25 || "Must be no more than 25 characters",
      ],
      discountAmountValidationRules: [
        value => !!value || "Amount is required.",
        value => value > 0 || "Amount must be positive."
      ],
      isCreateDiscountFormValid: false,
      isAmountHideDetails: true
    }
  },
  computed: {
    ...mapGetters(["getSelectedOperatingUnit"]),
    ...mapGetters("Settings", ["getFranchisesInOperatingUnit", "getIsLoadingDiscounts"]),
    isFormValid() {
      return this.discountName?.trim() !== "" &&
          this.promoCode?.trim().length >= 5 &&
          this.promoCode?.trim().length <= 25 &&
          this.discountAmount > 0 && this.beginDate !== null
    },
    isCreateDisabled() {
      return !this.isFormValid || this.getIsLoadingDiscounts
    },
    getDiscountTypeLabel() {
      return this.isPercentage ? "Percent *" : "Amount *"
    },
    getDiscountTypeHint() {
      return this.isPercentage ? "Value must be between 0 and 100." : "Value must be greater than $0.00."
    },
    headers() {
      return [
        { text: "Enrolled?", value: "isEnrolled", sortable: false },
        { text: "Franchise", value: "franchiseNumberAndName" },
        { text: "Begin Date", value: "beginDate", align: "center", sortable: false },
        { text: "End Date", value: "endDate", align: "center", sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions("Settings", ["createOperatingUnitDiscount"]),
    updateSelectedFranchises(event, item) {
      let index = this.selectedFranchises.findIndex(f => f.franchiseId === item.franchiseId)
      if (~index) {
        let matchingRecord = this.selectedFranchises[index]
        matchingRecord.isEnrolled = event
        this.selectedFranchises.splice(index, 1, matchingRecord)
      }
    },
    setItemBeginDateTime(franchiseId, date) {
      let index = this.selectedFranchises.findIndex(f => f.franchiseId === franchiseId)
      if (~index) {
        let matchingRecord = this.selectedFranchises[index]
        matchingRecord.beginDate = date
        this.selectedFranchises.splice(index, 1, matchingRecord)
      }
    },
    setItemEndDateTime(franchiseId, date) {
      let index = this.selectedFranchises.findIndex(f => f.franchiseId === franchiseId)
      if (~index) {
        let matchingRecord = this.selectedFranchises[index]
        matchingRecord.endDate = date
        this.selectedFranchises.splice(index, 1, matchingRecord)
      }
    },
    toUpperCase() {
      this.promoCode = this.promoCode.toUpperCase()
    },
    async createDiscount() {
      let amount = this.formatAmount()
      let createOperatingUnitFranchiseElectedDiscountDtos = this.selectedFranchises.filter(f => f.isEnrolled).map(f => {
          return createOperatingUnitFranchiseElectedDiscountDto(f.franchiseId, f.beginDate, f.endDate)
        })
      const createDiscountDto = new createOperatingUnitDiscountDto(
        this.discountName,
        this.promoCode,
        amount,
        !!this.isPercentage,
        this.getSelectedOperatingUnit.id,
        this.beginDate,
        this.endDate,
        this.$msal.getCurrentUserId(),
        createOperatingUnitFranchiseElectedDiscountDtos
      )

      await this.createOperatingUnitDiscount(createDiscountDto).then(() => this.closeDialog())
    },
    formatAmount() {
      return this.isPercentage ? Number(this.discountAmount / 100) : Number(this.discountAmount)
    },
    closeDialog() {
      this.$emit("close-dialog")
    },
    setBeginDateTime(dateTime) {
      this.beginDate = dateTime
      this.selectedFranchises.forEach(item => {
        this.$set(item, "beginDate", dateTime)
      })
    },
    setEndDateTime(dateTime) {
      this.endDate = dateTime
      this.selectedFranchises.forEach(item => {
        this.$set(item, "endDate", dateTime)
      })
    },
    selectAllContent(event) {
      if (event) {
        event.target.select()
      }
    },
    setCursorFocusOnAmountField() {
      if (this.$refs["settings-create-operating-unit-discount-dialog-amount-input"]) {
        this.$refs["settings-create-operating-unit-discount-dialog-amount-input"].focus()
      }
    },
    initDataTableItems() {
      return this.getFranchisesInOperatingUnit.map(franchise => {
        return {
          franchiseId: franchise.franchiseId,
          franchiseNumberAndName: franchise.franchiseNameAndNumber,
          beginDate: this.beginDate,
          endDate: this.endDate,
          isEnrolled: true
        }
      })
    }
  },
  created() {
    this.selectedFranchises = this.initDataTableItems()
  }
}
</script>
