<template>
  <v-container fluid class="ma-0 pa-0 mt-4">
    <v-card ref="job-notes-card-root" class="px-4" elevation="4" :loading="getIsLoadingJobNotes">
      <v-row>
        <v-col>
          <v-card-title ref="job-notes-card-title" class="headline ps-0">Job Notes</v-card-title>
        </v-col>
        <v-col cols="12" class="col-lg-3 col-sm-6">
          <v-select v-model="selectedJobNotesFilter" outlined class="cursor_pointer ms-4 me-4 mb-n4 mt-4" label="Filter" key="id" item-value="id" item-text="label" :items="jobNotesFilters"></v-select>
        </v-col>
      </v-row>
      <v-card-text>
        <v-col class="ma-0 pa-0">
          <v-textarea label="Enter a Note..." ref="jobNote" class="pb-2" outlined auto-grow :rules="jobNotesValidationRules" v-model="jobNote" counter="1000" />
        </v-col>
        <v-row class="float-end">
          <v-btn ref="job-notes-card-save-new-note-btn" ripple rounded class="primary mt-1 mb-4 me-4" :disabled="isSaveNoteButtonDisabled" @click="saveJobNote">Save Note</v-btn>
        </v-row>
        <v-row class="mt-8 mb-2">
          <v-responsive class="overflow-y-auto" max-height="800">
            <v-data-table
              ref="job-notes-card-table"
              class="flex-fill"
              mobile-breakpoint="900"
              loader-height="0"
              :loading="getIsLoadingJobNotes"
              :headers="jobNotesHeaders"
              :items="getNotesFiltered"
              loading-text="🔎 Fetching Notes 🔍"
              hide-default-footer
              item-key="itemKey"
              disable-pagination
            >
              <template v-slot:no-data>
                <v-col class="ma-0 pa-0 align-self-center d-flex">
                  <app-not-found title="No notes!" content="This job does not currently have any notes.">
                    <template v-slot:actions><div></div></template>
                  </app-not-found>
                </v-col>
              </template>
              <template v-slot:item.createdDate="{ value }">
                <app-date-time-locale-formatter class="text-md-body-1" :date-time-value="value" text-field-format="L LT" />
              </template>
              <template v-slot:item.createdBy="{ value }">
                <div class="text-md-body-1">{{ value }}</div>
              </template>
              <template v-slot:item.note="{ item }">
                <div>
                  <s v-if="item.isDeleted" class="text-md-body-1 text-pre-wrap">{{ item.note }}</s>
                  <span v-if="showDeletedBy(item)" class="text-md-body-1"><i>{{ getDeletedText(item) }}</i></span>
                </div>
                <span v-if="!item.isDeleted" class="text-md-body-1 text-pre-wrap">{{ item.note }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="isNoteDeletable(item)" color="error" ref="job-notes-card-delete-note-btn" @click="promptConfirmationDialog(item)" icon>
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-responsive>
        </v-row>
      </v-card-text>
    </v-card>
    <app-confirmation-dialog
      v-if="isDeleteJobNoteConfirmationDialogVisible"
      ref="job-notes-card-delete-note-confirmation-dialog"
      :is-dialog-visible="isDeleteJobNoteConfirmationDialogVisible"
      :is-loading="getIsLoadingJobNotes"
      title="Delete Job Note?"
      :content-text="`You're attempting to delete a job note. Are you sure you want to continue deleting?`"
      dialog-image="mdi-help"
      @closeDialog="setIsDeleteJobNoteConfirmationDialogVisible(false)"
      @confirmed="deleteJobNote"
    />
  </v-container>
</template>

<script>
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import AppNotFound from "@/components/AppNotFound"
import { mapActions, mapGetters } from "vuex"
import { createJobNoteDto } from "@/api/dtos/JunkDtos"
import AppConfirmationDialog from "@/components/AppConfirmationDialog.vue"
import { JobNoteType } from "@/enums/JobNoteType"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"

export default {
  name: "JobNotes",
  components: { AppConfirmationDialog, AppNotFound, AppDateTimeLocaleFormatter },
  data() {
    return {
      jobNote: "",
      jobNotesHeaders: [
        { text: "Created Date", align: "start", value: "createdDate", width: "15%" },
        { text: "Created By", align: "start", value: "createdBy", width: "15%" },
        { text: "Note", align: "start", value: "note" },
        { text: "Actions", align: "center", value: "actions", width: "10%" }
      ],
      jobNotesValidationRules: [value => value.length <= 1000 || "Note must be less than 1,000 characters."],
      isDeleteJobNoteConfirmationDialogVisible: false,
      noteToBeDeleted: {},
      jobNotesFilters: [
        { id: 0, label: "All history" },
        { id: JobNoteType.JOB_NOTE, label: "Notes only" },
        { id: JobNoteType.JOB_JOB_STATUS, label: "Job status changes" }
      ],
      selectedJobNotesFilter: JobNoteType.JOB_NOTE,
      textFieldFormat: "MM/D/YYYY h:mm A"
    }
  },
  methods: {
    ...mapActions("Job", ["createJunkJobNote", "softDeleteJunkJobNote"]),
    async saveJobNote() {
      if (this.$refs.jobNote.validate()) {
        const dto = createJobNoteDto(this.getCurrentJob.id, this.jobNote, this.$msal.getCurrentUserId())
        await this.createJunkJobNote(dto).then(() => {
          this.resetJobNote()
        })
      }
    },
    resetJobNote() {
      this.jobNote = ""
    },
    isNoteDeletable(item) {
      return !item.isDeleted && item.noteType === JobNoteType.JOB_NOTE
    },
    promptConfirmationDialog(item) {
      if (item.noteType !== JobNoteType.JOB_NOTE) {
        return // Safety to only allow notes to be deleted.
      }

      this.setIsDeleteJobNoteConfirmationDialogVisible(true)
      this.noteToBeDeleted = Object.assign({}, item)
    },
    setIsDeleteJobNoteConfirmationDialogVisible(isVisible) {
      this.isDeleteJobNoteConfirmationDialogVisible = isVisible
    },
    async deleteJobNote() {
      await this.softDeleteJunkJobNote(this.noteToBeDeleted.id).then(() => {
        this.setIsDeleteJobNoteConfirmationDialogVisible(false)
        this.noteToBeDeleted = Object.assign({}, {})
      })
    },
    showDeletedBy(item) {
      return this.selectedJobNotesFilter === 0 && item.isDeleted === true && item.noteType === JobNoteType.JOB_NOTE
    },
    getDeletedText(item) {
      return ` Deleted By: ${item.deletedBy} on ${this.toLocalDateTime(item.deletedDate)}`
    },
    toLocalDateTime(date) {
      if (date === undefined || date === null) return date
      dayjs.extend(localizedFormat)
      return dayjs(date).format(this.textFieldFormat)
    }
  },
  computed: {
    ...mapGetters("Job", ["getIsLoadingJobNotes", "getJobNotesOrderedByCreatedDate", "getCurrentJob", "getIsJobFieldsDisabled"]),
    isSaveNoteButtonDisabled() {
      return this.jobNote.trim().length <= 0
    },
    getNotesFiltered() {
      if (this.selectedJobNotesFilter !== 0) {
        return this.getJobNotesOrderedByCreatedDate.slice()
          .filter(x => x.noteType === this.selectedJobNotesFilter)
      }
      return this.getJobNotesOrderedByCreatedDate.slice()
    }
  }
}
</script>
