<template>
  <div>
    <v-card ref="job-receipt-recipients-card-root" class="mx-auto" max-width="550" min-height="100" flat>
      <v-card-title>
        <v-row class="align-baseline">
          <v-col class="text-no-wrap">
            <v-card-title class="ms-n4" ref="job-receipt-recipients-card-title">
              Send Statement to Contacts
            </v-card-title>
          </v-col>
          <v-col class="text-end">
            <v-btn ref="job-receipt-add-recipient-button" color="primary" outlined elevation="1" rounded @click="handleAddRecipient">
              Add Recipient
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-if="isPrimaryContactEmailDisclaimerVisible" class="text-center py-4">
              <strong>
                <span ref="job-receipt-recipients-no-primary-email-text">
                  This customer does not have a primary email address on record. Please add one or inform the customer they will not receive a copy of their junk job statement.
                </span>
              </strong>
            </v-row>
            <v-list-item v-else ref="job-receipt-recipients-list-content" :disabled="isPrimaryCustomerDisabled" :key="`primary-item`" :value="primaryRecipient">
              <template v-slot:default>
                <v-list-item-action>
                  <v-checkbox
                    ref="job-receipt-recipients-list-checkbox"
                    :disabled="isPrimaryCustomerDisabled"
                    :input-value="isPrimaryContactInRecipients"
                    color="primary"
                    @change="handlePrimaryEmailSelection"
                  >
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content ref="job-receipt-recipients-list-item">
                  <v-list-item-title class="text-h5">{{ primaryRecipient.email }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row v-if="getAllEligibleReceiptRecipients.length > 0">
          <v-col>
            <v-divider class="mx-0 mt-1"></v-divider>
            <div class="text-h5 mt-4">Other Contacts:</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list shaped>
              <v-list-item-group ref="job-receipt-recipients-list-root" :value="getSelectedEmailReceiptRecipients" @change="setSelectedEmailReceiptRecipients" multiple>
                <template v-for="(item, i) in getAllEligibleReceiptRecipients">
                  <v-list-item ref="job-receipt-recipients-list-content" :key="`item-${i}`" :value="item">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox ref="job-receipt-recipients-list-checkbox" :input-value="active" color="primary"> </v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content ref="job-receipt-recipients-list-item">
                        <v-list-item-title class="text-h5">{{ item.email }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <job-receipt-add-recipient-dialog
      ref="job-receipt-add-recipient-dialog-root"
      v-if="isAddRecipientDialogVisible"
      :is-dialog-visible="isAddRecipientDialogVisible"
      @cancel="handleCancelAddRecipient"
      @save="handleSaveAddRecipient"
    ></job-receipt-add-recipient-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import JobReceiptAddRecipientDialog from "@/components/JobReceiptAddRecipientsDialog"
import { v4 } from "uuid"

export default {
  name: "JobReceiptRecipientsCard",
  components: { JobReceiptAddRecipientDialog },
  props: {
    isPrimaryCustomerDisabled: {
      type: Boolean,
      default: false
    },
    showIsPrimaryContactEmailNotProvidedDisclaimer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAddRecipientDialogVisible: false,
      primaryRecipient: {}
    }
  },
  computed: {
    ...mapGetters("Job", ["getAllCustomerContacts", "getAllEligibleReceiptRecipients", "getSelectedEmailReceiptRecipients"]),
    isPrimaryContactEmailDisclaimerVisible() {
      return this.showIsPrimaryContactEmailNotProvidedDisclaimer && Object.keys(this.primaryRecipient).length === 0
    },
    isPrimaryContactInRecipients() {
      return this.getSelectedEmailReceiptRecipients.some(recipient => {
        return this.compareObjects(recipient, this.primaryRecipient)
      })
    }
  },
  methods: {
    ...mapActions("Job", [
      "setAllEligibleReceiptRecipients",
      "setSelectedEmailReceiptRecipients",
      "addEligibleReceiptRecipient",
      "addSelectedEmailReceiptRecipient",
      "removeSelectedEmailReceiptRecipient"
    ]),
    compareObjects(obj1, obj2) {
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false
        }
      }

      return true
    },
    async handlePrimaryEmailSelection(isSelected) {
      if (isSelected) {
        await this.addSelectedEmailReceiptRecipient(this.primaryRecipient)
      } else {
        await this.removeSelectedEmailReceiptRecipient(this.primaryRecipient)
      }
    },
    setIsAddRecipientDialogVisible(isVisible) {
      this.isAddRecipientDialogVisible = isVisible
    },
    handleCancelAddRecipient() {
      this.setIsAddRecipientDialogVisible(false)
    },
    async handleSaveAddRecipient(email) {
      let recipient = {
        listId: v4(),
        firstName: "",
        lastName: "",
        email: email,
        isPrimary: false
      }
      await this.addEligibleReceiptRecipient(recipient)
      await this.addSelectedEmailReceiptRecipient(recipient)
      this.isAddRecipientDialogVisible = false
    },
    handleAddRecipient() {
      this.setIsAddRecipientDialogVisible(true)
    },
    async init() {
      let filteredRecipients = this.getAllCustomerContacts.filter(contact => contact.emailPrimary !== "" && contact.refusedToProvideEmail === false && contact.isPrimary === false)
      let allEligibleRecipients = (filteredRecipients ?? []).map(contact => ({
        listId: v4(),
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.emailPrimary,
        isPrimary: contact.isPrimary
      }))

      await this.setAllEligibleReceiptRecipients(allEligibleRecipients)

      let primary = this.getAllCustomerContacts.find(recipient => recipient.isPrimary === true)

      if (primary !== undefined && primary.refusedToProvideEmail === false && primary.emailPrimary?.length > 0) {
        let recipient = {
          listId: v4(),
          firstName: primary.firstName,
          lastName: primary.lastName,
          email: primary.emailPrimary,
          isPrimary: primary.isPrimary
        }
        this.primaryRecipient = Object.assign({}, recipient)

        if (this.isPrimaryCustomerDisabled) {
          await this.setSelectedEmailReceiptRecipients([recipient])
        }
      }
    }
  },
  async created() {
    await this.init()
  }
}
</script>
