<template>
  <v-layout>
    <transition name="flip">
      <v-card
        v-if="isPrimaryCardDisplayed"
        :key="isPrimaryCardDisplayed"
        :loading="getIsLoadingCustomerContactsWithAddresses"
        class="flex d-flex flex-column justify-center"
        style="min-width: 150px; min-height: 200px"
        @click="setIsContactsCardDisplayed(true)"
        ref="job-customer-card-root"
        elevation="4"
      >
        <v-card-title v-if="isBusinessHeaderVisible && isPrimaryCardTitleAndActionsVisible" class="pb-0 mb-n4 headline" ref="job-customer-card-business-title">
          <base-tool-tip
            v-if="isCharityJob"
            dialog-title="Charity Job"
            icon-color="primary"
            tooltip-icon="mdi-charity"
            tooltip-color="primary"
            :is-top-dialog="true"
            nudge-dialog="100"
          ></base-tool-tip>
          <base-tool-tip
            v-else
            dialog-title="Business Customer"
            icon-color="primary"
            tooltip-icon="mdi-domain"
            tooltip-color="primary"
            :is-top-dialog="true"
            nudge-dialog="100"
          ></base-tool-tip>
          <div class="ps-4 mb-0" ref="job-customer-card-business-title-text" v-text="getCustomerContactsWithAddresses.businessName"></div>
        </v-card-title>
        <v-card-title v-if="isPrimaryCardTitleAndActionsVisible" class="mt-4 mt-sm-1 pb-0 headline" ref="job-customer-card-customer-title">
          <span ref="job-customer-card-customer-title-text" class="ps-10">{{ primaryCustomerName }}</span>
        </v-card-title>
        <v-card-text class="py-0" v-if="isPrimaryCardTitleAndActionsVisible">
          <v-row class="me-8">
            <v-col>
              <v-text-field class="ps-12 my-2 pa-0" ref="job-customer-card-phone-number-text" v-text="formatPhoneNumber(getPrimaryContactDetails.phonePrimary)"></v-text-field>
              <v-text-field class="ps-12 mb-4 pa-0" ref="job-customer-card-email-text" v-text="getPrimaryContactDetails.emailPrimary"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="isPrimaryCardTitleAndActionsVisible">
          <v-divider class="ma-2"></v-divider>
          <v-row class="py-4 justify-space-around px-2">
            <base-tool-tip
              v-if="getPrimaryContactDetails.isOptedIntoTextMessaging"
              ref="job-customer-card-primary-opt-into-text-messaging-tool-tip"
              dialog-title="Opted Into Text Messaging"
              :is-right-dialog="true"
              tooltip-icon="mdi-message-text"
              nudge-dialog="0"
              :is-bottom-dialog="true"
            ></base-tool-tip>

            <base-tool-tip
              v-else
              ref="job-customer-card-primary-opt-out-text-messaging-tool-tip"
              dialog-title="Opted out of Text Messaging"
              :is-right-dialog="true"
              tooltip-icon="mdi-message-bulleted-off"
              nudge-dialog="0"
              :is-bottom-dialog="true"
            ></base-tool-tip>

            <base-tool-tip
              ref="job-customer-card-preferred-method-of-contact-tool-tip"
              tooltip-color="secondary"
              icon-color="secondary"
              :tooltip-icon="preferredMethodOfContactIcon(getPrimaryContactDetails.preferredMethodOfContact)"
              :dialogTitle="formatPreferredMethodOfContactToolTipDialogText(getPrimaryContactDetails.preferredMethodOfContact)"
              nudge-dialog="0"
              :is-bottom-dialog="true"
            ></base-tool-tip>

            <base-tool-tip
              ref="job-customer-card-display-all-addresses"
              @tooltipClicked="setIsAddressesCardVisible(true)"
              dialog-title="Display All Customer Addresses"
              :is-right-dialog="true"
              tooltip-icon="mdi-map-marker"
              nudge-dialog="0"
              :is-bottom-dialog="true"
            ></base-tool-tip>
          </v-row>
          <v-divider class="ma-2"></v-divider>
        </v-card-actions>
      </v-card>

      <v-card
        v-else-if="isContactsCardDisplayed"
        :loading="getIsLoadingCustomerContactsWithAddresses"
        class="flex d-flex flex-column pb-2"
        ref="job-customer-contact-card-root"
        elevation="4"
      >
        <v-card-title class="py-4">
          <span class="ps-4 headline">All Contacts</span>
          <v-spacer></v-spacer>
          <v-btn v-if="isEditCustomerButtonVisible" class="me-4" color="primary" @click="promptEditCustomerDialog(true)">Edit Customer</v-btn>
          <v-icon large class="float-end" color="primary" @click="setIsContactsCardDisplayed(false)">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>
          <span class="ps-4">{{ getAllContactsSubtitleText }}</span>
        </v-card-subtitle>
        <v-card-text class="px-8 justify-center align-self-center text-center align-content-center">
          <v-responsive class="overflow-y-auto" max-height="850">
            <v-row class="justify-center align-self-center text-center align-content-center">
              <v-col class="flex-column d-flex" v-for="(contact, index) in getAllCustomerContacts" :key="index">
                <v-card class="primary-light-solid-border flex d-flex flex-column mb-4" @click="promptEditContactDialog(contact)" style="max-width: 450px" :disabled="getCustomerContactsWithAddresses.editContactsIsDisabled">
                  <v-card-title class="ma-0 pa-2 primary--text">
                    <span v-if="contact.firstName" class="pe-2 headline text-break" ref="job-customer-contact-card-first-name" v-text="contact.firstName"></span>
                    <span class="headline text-break" ref="job-customer-contact-card-last-name" v-text="contact.lastName"></span>
                    <v-icon v-if="contact.isPrimary" ref="job-customer-contact-card-is-primary-icon" class="ps-2" color="primary">mdi-account-star-outline</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn class="ms-2" @click.stop="$emit('deleteContact', contact)" icon>
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="ma-0">
                    <v-text-field class="my-2 pa-0 text-no-wrap" ref="job-customer-contact-card-phone-number-text" v-text="formatPhoneNumber(contact.phonePrimary)"></v-text-field>
                    <v-text-field class="pa-0 text-wrap" ref="job-customer-contact-card-email-text" v-text="contact.emailPrimary"></v-text-field>
                  </v-card-text>
                  <v-card-actions class="ma-0 pa-0 pb-2 fill-height">
                    <v-row class="py-4 px-2 justify-space-around">
                      <v-divider class="ma-4"></v-divider>
                      <div class="pt-1">
                        <base-tool-tip
                          ref="job-customer-contact-card-preferred-method-of-contact-tool-tip"
                          tooltip-color="secondary"
                          icon-color="secondary"
                          :tooltip-icon="preferredMethodOfContactIcon(contact.preferredMethodOfContact)"
                          :dialogTitle="formatPreferredMethodOfContactToolTipDialogText(contact.preferredMethodOfContact)"
                          nudge-dialog="0"
                          :is-bottom-dialog="true"
                        ></base-tool-tip>
                      </div>
                      <div v-if="contact.isOptedIntoTextMessaging" class="pt-1 ms-6">
                        <base-tool-tip
                          ref="job-customer-contact-card-is-opted-into-text-messaging-tool-tip"
                          tooltip-color="primary"
                          icon-color="primary"
                          tooltip-icon="mdi-message-text"
                          dialog-title="Opted Into Text Messaging"
                          :is-bottom-dialog="true"
                        ></base-tool-tip>
                      </div>
                      <v-divider class="ma-4"></v-divider>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-responsive>
        </v-card-text>
      </v-card>

      <v-card v-else-if="isAddressesCardVisible" class="flex d-flex flex-column pb-2" elevation="4">
        <v-card-title class="py-4">
          <span class="ps-4 headline">All Addresses</span>
          <v-spacer></v-spacer>
          <v-icon large class="float-end" color="primary" @click="setIsAddressesCardVisible(false)">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>
          <span class="ps-4">Select any card below to edit.</span>
        </v-card-subtitle>
        <v-card-text class="px-8 justify-center align-self-center text-center align-content-center">
          <v-responsive v-if="getAllCustomerContactAddresses.length > 0" class="overflow-y-auto" max-height="750">
            <v-row class="justify-center align-self-center text-center align-content-center">
              <v-col class="flex-column d-flex" v-for="(address, index) in getAllCustomerContactAddresses" :key="index">
                <v-card class="primary-light-solid-border flex d-flex flex-column mb-4" @click="promptEditAddressDialog(address, true)" max-width="450" min-width="300" min-height="100">
                  <v-card-title class="mx-0 py-1 primary--text justify-center">
                    <v-btn @click.stop="promptDeleteAddressConfirmationDialog(address)" icon>
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="ma-0">
                    <v-text-field
                      class="pa-0 text-break text-md-body-1 text-center justify-center align-center align-content-center"
                      ref="job-customer-contact-card-email-text"
                      v-text="formatCustomerAddressItemText(address)"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-responsive>
          <v-row v-else>
            <v-col class="flex-column d-flex">
              <v-btn
                class="mb-4 primary justify-start align-self-start"
                ref="job-customer-card-prompt-create-customer-address-dialog-btn"
                @click="$emit('promptCreateCustomerAddressDialog')"
                ripple
                rounded
                large
                elevation="4"
              >Create a new Customer Address</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </transition>

    <app-edit-address-dialog
      v-if="isEditAddressDialogVisible"
      :is-dialog-visible="isEditAddressDialogVisible"
      :is-loading="getIsLoadingUpdateCustomerAddress"
      @closeDialog="promptEditAddressDialog({}, false)"
      :address-line1="selectedAddress.addressLine1"
      :address-line2="selectedAddress.addressLine2"
      :country="selectedAddress.country"
      :city="selectedAddress.city"
      :state="selectedAddress.stateOrProvince"
      :zip="selectedAddress.zipCode"
      :is-user-validated="selectedAddress.isUserValidated"
      :complex="selectedAddress.complex"
      @confirm="updateCustomerAddressHandler($event)"
      @confirmUserValidated="updateCustomerAddressUserValidatedHandler($event)"
    ></app-edit-address-dialog>

    <app-edit-customer-dialog
      v-if="isEditCustomerDialogVisible"
      :is-dialog-visible="isEditCustomerDialogVisible"
      @closeDialog="promptEditCustomerDialog(false)"
    />
  </v-layout>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip"
import { mapActions, mapGetters } from "vuex"
import phoneNumberFormatter from "@/utils/PhoneNumberFormatter"
import { formatAddressText } from "@/utils/FormatAddressText"
import AppEditAddressDialog from "@/components/AppEditAddressDialog"
import { putCustomerAddressDto, putCustomerAddressUserValidatedDto } from "@/api/dtos/JunkDtos"
import AppEditCustomerDialog from "@/components/AppEditCustomerDialog"

export default {
  name: "JobCustomerCard",
  components: { AppEditCustomerDialog, AppEditAddressDialog, BaseToolTip },
  data() {
    return {
      isPrimaryCardDisplayed: true,
      isContactsCardDisplayed: false,
      isAddressesCardVisible: false,
      isEditAddressDialogVisible: false,
      isEditCustomerDialogVisible: false,
      selectedAddress: {}
    }
  },
  computed: {
    ...mapGetters("Job", [
      "getAllCustomerContacts",
      "getCustomerContactsWithAddresses",
      "getPrimaryContactDetails",
      "getIsLoadingCustomerContactsWithAddresses",
      "getCurrentJob",
      "getAllCustomerContactAddresses",
      "getIsLoadingCreateContact",
      "getIsLoadingUpdateCustomerAddress"
    ]),
    getAllContactsSubtitleText() {
      return this.getCustomerContactsWithAddresses.editContactsIsDisabled ? "Editing contact details is unavailable while another franchise has an active job for this customer. Please contact service desk for further assistance." : "Select any card below to edit."
    },
    isBusinessHeaderVisible() {
      return this.getCustomerContactsWithAddresses?.isBusiness ?? false
    },
    isCharityJob() {
      return this.getCurrentJob.isCharity ?? false
    },
    isPrimaryCardTitleAndActionsVisible() {
      return !this.getIsLoadingCustomerContactsWithAddresses && !this.isCustomerContactsWithAddressesEmpty
    },
    isCustomerContactsWithAddressesEmpty() {
      return this.getCustomerContactsWithAddresses === null ||
        this.getCustomerContactsWithAddresses === undefined ||
        Object.keys(this.getCustomerContactsWithAddresses).length === 0;
    },
    primaryCustomerName() {
      return `${this.getPrimaryContactDetails?.firstName} ${this.getPrimaryContactDetails?.lastName}`
    },
    isEditCustomerButtonVisible() {
      return this.getCustomerContactsWithAddresses.isBusiness || this.getCustomerContactsWithAddresses.isCustomerTypeEditable
    }
  },
  methods: {
    ...mapActions("Job", ["updateCustomerAddress", "updateCustomerAddressUserValidated", "fetchJunkJobAddresses"]),
    formatPhoneNumber(phoneNumber) {
      return phoneNumberFormatter.formatPhoneNumber(phoneNumber)
    },
    formatCustomerAddressItemText(item) {
      return formatAddressText(item)
    },
    formatPreferredMethodOfContactToolTipDialogText(preferredMethodOfContact) {
      return preferredMethodOfContact?.trim() ? `Preferred Method of Contact: ${preferredMethodOfContact}` : "No Preferred Method of Contact"
    },
    preferredMethodOfContactIcon(preferredMethodOfContact) {
      const methodOfContact = preferredMethodOfContact?.toLowerCase() ?? ""
      if (methodOfContact === "phone") return "mdi-phone-in-talk-outline"
      if (methodOfContact === "email") return "mdi-email-outline"
      if (methodOfContact === "text") return "mdi-cellphone-text"
      return "mdi-blank"
    },
    setIsContactsCardDisplayed(isContactsCardDisplayed) {
      this.isContactsCardDisplayed = isContactsCardDisplayed
      this.emitIsContactOrAddressCardVisible(isContactsCardDisplayed)
      this.setIsPrimaryCardDisplayed(!isContactsCardDisplayed)
    },
    setIsAddressesCardVisible(isVisible) {
      this.isAddressesCardVisible = isVisible
      this.emitIsContactOrAddressCardVisible(isVisible)
      this.setIsPrimaryCardDisplayed(!isVisible)
    },
    emitIsContactOrAddressCardVisible(isVisible) {
      this.$emit("isContactOrAddressCardVisible", isVisible)
    },
    setIsPrimaryCardDisplayed(isDisplayed) {
      this.isPrimaryCardDisplayed = isDisplayed
    },
    promptEditCustomerDialog(isVisible) {
      this.isEditCustomerDialogVisible = isVisible
    },
    promptEditContactDialog(contact) {
      this.$emit("promptDialog", contact)
    },
    promptEditAddressDialog(address, isVisible) {
      this.isEditAddressDialogVisible = isVisible
      this.selectedAddress = address
    },
    promptDeleteAddressConfirmationDialog(address) {
      this.$emit("deleteAddress", address)
    },
    async updateCustomerAddressUserValidatedHandler(event) {
      let dto = putCustomerAddressUserValidatedDto(this.getCurrentJob.customerId, this.selectedAddress.id, event.addressLine1, event.addressLine2, event.city, event.state, event.zip, event.country, event.complex)
      await this.updateCustomerAddressUserValidated(dto).then(() => this.resetSelectedCustomerAddress())
      await this.fetchJunkJobAddresses(this.getCurrentJob.id)
    },
    async updateCustomerAddressHandler(event) {
      let dto = putCustomerAddressDto(this.getCurrentJob.customerId, this.selectedAddress.id, event.addressLine2, event.complex)
      await this.updateCustomerAddress(dto).then(() => this.resetSelectedCustomerAddress())
      await this.fetchJunkJobAddresses(this.getCurrentJob.id)
    },
    resetSelectedCustomerAddress() {
      this.selectedAddress = {}
      this.isEditAddressDialogVisible = false
    }
  }
}
</script>

<style scoped>
.flip-enter-active {
  transition: all 0.7s ease;
}

.flip-leave-active {
  transition: all 0.7s ease;
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
</style>
